import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import jwt_decode from 'jwt-decode';
import { takeWhile } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { App, User } from '../../../models';
import { AppsService, AuthService, HelpFeedbackServiceModel, LogoutTimerService, TokenService, UsersService } from '../../../services';
import { HelpFeedbackLogicService } from '../../../services/logic-services';

@Component({
    selector: 'kh-primary-menu',
    templateUrl: './primary-menu.component.html',
    styleUrls: ['./primary-menu.component.scss'],
})
export class PrimaryMenuComponent implements OnInit, OnDestroy {

    private _alive = true;

    smartsLogo: string = environment.logos.primaryLogo;
    user: User = new User();
    apps: App[] = [];
    app: App;
    accessText: string;
    help = 'help';
    releaseNotes = 'release-notes';

    get model(): HelpFeedbackServiceModel {
        return this._helpFeedbackService.model;
    }

    constructor(
        private _appsService: AppsService,
        private _authService: AuthService,
        private _helpFeedbackService: HelpFeedbackLogicService,
        private _logoutTimerService: LogoutTimerService,
        private _router: Router,
        private _tokenService: TokenService,
        private _usersService: UsersService,
    ) { }

    ngOnInit(): void {
        this._usersService.reloadUser();

        this._usersService.user
            .pipe(takeWhile(() => this._alive))
            .subscribe(user => {
                this.user = user;
            });

        this._appsService.app
            .pipe(takeWhile(() => this._alive))
            .subscribe(app => {
                if (app) {
                    this.app = app;
                }
            });

        this._tokenService.token
            .pipe(takeWhile(() => this._alive))
            .subscribe(token => {
                if (token) {
                    const t = jwt_decode<any>(token);
                    if (t.isPlatformAdmin) {
                        this.accessText = 'Platform Admin';
                    }
                    else if (t.isAppAdmin && !t.isPlatformAdmin) {
                        this.accessText = 'App Admin';
                    }
                    else if (t.isProjectAdmin && !t.isAppAdmin && !t.isPlatformAdmin) {
                        this.accessText = 'Project Admin';
                    }
                    else if (t.isPlainUser) {
                        this.accessText = 'Plain User';
                    }
                }
            });

        this._helpFeedbackService.initModel();
    }

    ngOnDestroy(): void {
        this._alive = false;
    }

    openRoute(route: string): void {
        if (route === this.help) {
            window.open(environment.blobStorage + 'public/SmartsHelpFile.pdf', '_blank');
            return;
        }

        if (route === this.releaseNotes) {
            window.open(environment.blobStorage + 'public/SmartsReleaseNotes.pdf', '_blank');
            return;
        }

        this._router.navigate([route]);
    }

    openFeedbackForm(): void {
        this.model.showForm = true;
    }

    logout(): void {
        this.stopSessionTimer();
        this._authService.logout();
    }

    stopSessionTimer(): void {
        this._logoutTimerService.resetTimer();
        this._logoutTimerService.stopTimer();
    }
}
