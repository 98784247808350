import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { App, User } from '../models';
import { Site } from '../models/site';
import { SiteRequestDataService } from './data-services';

@Injectable({
    providedIn: 'root'
})

export class SiteRequestService {
    private _requestedSite: Site;
    private _requestedSites: Site[] = [];
    private _filesToBeRemoved: string[] = [];
    private _existingFiles: string[] = [];

    requestedSites: BehaviorSubject<Site[]> = new BehaviorSubject<Site[]>(null);
    requestedSite: BehaviorSubject<Site> = new BehaviorSubject<Site>(null);
    updateBool: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    siteRequestsLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    constructor(
        private _snackBar: MatSnackBar,
        private _siteRequestData: SiteRequestDataService
    ) { }

    setUpdateBool(value: boolean): void {
        this.updateBool.next(value);
    }

    setLoading(value: boolean): void {
        this.siteRequestsLoading.next(value);
    }

    setRequestedSite(requestedSite: Site): void {
        this.requestedSite.next(requestedSite);
    }

    disconnectSite(): void {
        this.requestedSite.next(null);
    }

    setFileIdsToBeRemoved(fileIds: string[]): void {
        this._filesToBeRemoved = fileIds;
    }

    createSiteRequest(app: App, siteRequestData): void {
        this.siteRequestsLoading.next(true);

        this._siteRequestData.createSiteRequest(app, siteRequestData)
            .subscribe({
                next: () => { },
                complete: () => {
                    this.siteRequestsLoading.next(false);

                    this._snackBar.open(`${app.aliases.site.singular} Request Succesfully Submitted`, '', {
                        duration: 2000,
                    });
                }
            });
    }

    getSiteRequests(app: App, user: User): void {
        this.siteRequestsLoading.next(true);

        this._siteRequestData.getSiteRequests(app, user)
            .subscribe({
                next: (response: unknown) => {
                    this.handleGetSiteRequests(response);
                },
                complete: () => {
                    this.siteRequestsLoading.next(false);
                }
            });
    }

    handleGetSiteRequests(response: any): void {
        this._requestedSites = response;
        this.setSiteRequests(response);
    }

    setSiteRequests(requestedSites: Site[]): void {
        this.requestedSites.next(requestedSites);
    }

    deleteSiteRequest(app: App, site: Site, user: User): void {
        this.siteRequestsLoading.next(true);

        this._siteRequestData.deleteSiteRequest(app, site)
            .subscribe({
                next: () => { console.log(`${app.aliases.site.singular} Requested Deleted`); },
                error: () => this._snackBar.open('An error has occurred while deleting.', '', { duration: 2000, }),
                complete: () => {
                    this.getSiteRequests(app, user);

                    this._snackBar.open(`${app.aliases.site.singular} request successfully Deleted.`, '', { duration: 2000, });
                }
            });
    }

    updateSiteRequest(app: App, siteRequestId: string, siteRequestData: any): void {
        this.siteRequestsLoading.next(true);

        siteRequestData.append('filesToBeRemoved', this._filesToBeRemoved);

        this._siteRequestData.updateSiteRequest(app, siteRequestId, siteRequestData)
            .subscribe({
                complete: () => {
                    this.siteRequestsLoading.next(false);

                    this._snackBar.open(`${app.aliases.site.singular} request successfully Updated.`, '', { duration: 2000, });
                }
            });
    }

}
