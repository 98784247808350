/* istanbul ignore file */
export class AppPropertiesAliasMap {
    project = new AppPropertyAlias();
    site = new AppPropertyAlias();
}

export class AppPropertyAlias {
    plural = "";
    singular = "";
}
