import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Subject } from 'rxjs';
import * as XLSX from 'xlsx';

import { App } from 'src/app/models/app';
import { ExcelBulkSiteUploadDuplicateSitesDialogComponent } from '../../components/dialogs/bulk-site-upload-dialog/excel/excel-bulk-site-upload-duplicate-sites-dialog/excel-bulk-site-upload-duplicate-sites-dialog.component';
import { ExcelBulkSiteUploadErrorDialogComponent } from '../../components/dialogs/bulk-site-upload-dialog/excel/excel-bulk-site-upload-error-dialog/excel-bulk-site-upload-error-dialog.component';
import { LoaderDialogComponent } from '../../components/dialogs/loader-dialog/loader-dialog.component';
import { BulkSiteImportResultData, KHFileInfo, Site } from '../../models';
import { SitesImportDataService } from '../data-services/sites-import-data.service';
import { SitesService } from '../sites.service';
import { ExcelSiteProperties } from './models/excelSiteProperties';

@Injectable({
    providedIn: 'root'
})
export class ExcelBulkSiteUploadLogicService {

    private startTimer = new Subject<boolean>();

    startTimer$ = this.startTimer.asObservable();

    uploadedFile: KHFileInfo[];

    constructor(private _sitesImportDataService: SitesImportDataService, private _sitesService: SitesService,
        private _snackBar: MatSnackBar, private _dialog: MatDialog) { }

    verifyExcelData(columns: string[], isActiveLicensePerSite: boolean): boolean {
        let headers = [];
        if (isActiveLicensePerSite)
            headers = [`number`, `name`, `address`, `city`, `state`, `zipCode`, `chargeNumber`, `active`, `show`];
        else
            headers = [`number`, `name`, `address`, `city`, `state`, `zipCode`, `active`, `show`];

        const optionalFields = [`latitude`, `longitude`];

        const allHeadersPresent = headers.every(header => columns.includes(header));

        const noExtraColumns = columns.every(column => headers.includes(column) || optionalFields.includes(column));

        if (!allHeadersPresent || !noExtraColumns) {
            this.openInvalidHeadersSnackBar(isActiveLicensePerSite);
            return false;
        }

        return true;
    }

    openInvalidHeadersSnackBar(isActiveLicensePerSite: boolean): void {
        if (!isActiveLicensePerSite) {
            this._snackBar.open(`Please make sure the excel file contains the following headers (case sensitive) in the following order: number, name, address, city, state, zipCode, active, show, latitude (optional), longitude (optional)`, "Close", { duration: 10000 });
            return;
        }

        this._snackBar.open(`Please make sure the excel file contains the following headers (case sensitive) in the following order: number, name, address, city, state, zipCode, chargeNumber, active, show, latitude (optional), longitude (optional)`, "Close", { duration: 10000 });
    }

    startTimerToCheckIsProcessing(app: App): void {
        let intervalId: NodeJS.Timeout;

        intervalId = setInterval(() => {
            this._sitesImportDataService.checkBulkSitesProcessing(app.id)
                .subscribe({
                    next: (result: BulkSiteImportResultData) => {
                        if (!result.bulkSitesProcessing && !result.hasErrors) {
                            clearInterval(intervalId);
                            this.startTimer.next(false);
                        }
                        else if (result.hasErrors) {
                            this.startTimer.next(false);
                            this._dialog.open(ExcelBulkSiteUploadErrorDialogComponent, {
                                data: result,
                                disableClose: false,
                                width: "750px"
                            });
                            clearInterval(intervalId);
                        }
                    },
                    error: () => {
                        clearInterval(intervalId);
                        this._snackBar.open(`Error importing bulk ${app.aliases.site.plural.toLocaleLowerCase()}`, '', { duration: 5000 });
                        this._dialog.closeAll();
                    }
                });
        }, 5000);
    }

    setStartTimer(): void {
        this.startTimer.next(true);
    }

    validateExcelFileForDuplicateUpload(excelSitePropertiesList: ExcelSiteProperties[], currentSites: Site[]): Site[] {
        const duplicateSite: Site[] = [];

        excelSitePropertiesList.forEach(excelSiteProperties => {
            const duplicates = currentSites.filter((currentSite: Site) => currentSite.siteNo === excelSiteProperties.number);
            if (duplicates.length > 0 && !duplicateSite.includes(duplicates[0])) {
                duplicateSite.push(duplicates[0]);
            }
        });

        return duplicateSite;
    }

    extractExcelSitePropertiesFromExcelWorkBook(workBook: XLSX.WorkBook): ExcelSiteProperties[] {
        let excelSitePropertiesList: ExcelSiteProperties[] = [];
        workBook.SheetNames.forEach(sheetname => {
            const sheet = workBook.Sheets[sheetname];
            excelSitePropertiesList = XLSX.utils.sheet_to_json<ExcelSiteProperties>(sheet, { raw: false, dateNF: "dd/mm/yyyy" });
        });

        return excelSitePropertiesList;
    }

    getExcelColumnsFromWorkBook(workBook: XLSX.WorkBook): string[] {
        const sheet = workBook.Sheets[workBook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 }) as string[][];
        const columns = jsonData[0] as string[];

        return columns;
    }

    bulkSitesImport(appId: string, projId: string, excelSitePropertiesList: ExcelSiteProperties[], sitesAlias: string): void {
        this._sitesImportDataService.bulkSitesImport(appId, projId, excelSitePropertiesList)
            .subscribe({
                next: () => {
                    this.setStartTimer();
                    this._snackBar.open(`${sitesAlias} Uploading`, "Close", { duration: 5000 });
                },
                error: () => {
                    this._snackBar.open(`Error importing bulk ${sitesAlias.toLocaleLowerCase()}`, '', { duration: 5000 });
                    this._dialog.closeAll();
                }
            });
    }

    deleteSelectedFile(): void {
        this.uploadedFile = [];
    }

    processExcelData(excelSitePropertiesList: ExcelSiteProperties[], duplicates: Site[], appId: string, projId: string, projectAliasLowerCase: string, sitesAlias: string, sitesAliasLowerCase: string, dialog: MatDialog): void {

        if (!this.validateAppAndProject(appId, projId, projectAliasLowerCase, sitesAliasLowerCase))
            return;

        if (duplicates.length > 0)
            this.showDuplicateSitesDialog(duplicates, excelSitePropertiesList, appId, projId, sitesAlias, sitesAliasLowerCase, dialog);

        else
            this.processSiteUpload(excelSitePropertiesList, appId, projId, sitesAlias, sitesAliasLowerCase);
    }

    showDuplicateSitesDialog(duplicates: Site[], excelSitePropertiesList: ExcelSiteProperties[], appId: string, projId: string, sitesAlias: string, sitesAliasLowerCase: string, dialog: MatDialog): void {
        const duplicateDialog = dialog.open(ExcelBulkSiteUploadDuplicateSitesDialogComponent, {
            data: duplicates,
            disableClose: false,
            width: "750px",
            height: "536px"
        });

        duplicateDialog.afterClosed().subscribe(dialogResult => {
            if (dialogResult) {
                this.processSiteUpload(excelSitePropertiesList, appId, projId, sitesAlias, sitesAliasLowerCase);
            }

        });
    }

    processSiteUpload(excelSitePropertiesList: ExcelSiteProperties[], appId: string, projId: string, sitesAlias: string, sitesAliasLowerCase: string): void {
        this._dialog.open(LoaderDialogComponent, {
            data: `Validating and Inserting ${sitesAliasLowerCase}, this may take a few minutes...`,
            disableClose: true
        });

        this.uploadedFile = [];

        this.bulkSitesImport(appId, projId, excelSitePropertiesList, sitesAlias);
    }

    validateAppAndProject(appId: string, projId: string, projectAliasLowerCase: string, sitesAliasLowerCase: string): boolean {
        if (!appId || !projId) {
            this._snackBar.open(`Please select a  ${projectAliasLowerCase} before uploading ${sitesAliasLowerCase}.`, "Close", { duration: 5000 });
            return false;
        }

        return true;
    }
}
