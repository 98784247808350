import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import MarkerClusterer from '@google/markerclustererplus';
import { Subject } from 'rxjs';

import { FormBuilder } from '@angular/forms';
import { App, GeoJsonFeature, KHMapMarker, Point, Position, Project, Site } from '../../models';
import { MapFilterMenuOptionsModel } from '../../models/map-filter-menu-options-model';
import { MapIcon, MapPin } from '../../models/map-pin-model';
import { GeoJsonFeatureType, SiteTypes } from '../../shared/enums';
import { MapFiltersDataService } from '../data-services';
import { SiteMapLogicServiceModel } from './models/map';

@Injectable({
    providedIn: 'root'
})
export class SiteMapLogicService {

    private _sitesAddedToMap: Subject<boolean> = new Subject<boolean>();
    sitesAddedToMap$ = this._sitesAddedToMap.asObservable();

    model: SiteMapLogicServiceModel = new SiteMapLogicServiceModel();

    mapTypeRoadMap = 'roadmap';
    mapTypeSatellite = 'hybrid';
    mapTypeTerrain = 'terrain';

    constructor(private _mapFiltersDataService: MapFiltersDataService, private _snackBar: MatSnackBar, private _formBuilder: FormBuilder
    ) { }

    initModel(): void {
        if (this.model === undefined) this.model = new SiteMapLogicServiceModel();
        if (this.model.mapFilterMenuOptions === undefined) this.model.mapFilterMenuOptions = new MapFilterMenuOptionsModel();
        this.model.mapLayersForm = this._formBuilder.group({
            terrain: [false],
            roadNames: [true],
            pointsOfInterest: [false]
        });
    }

    resetMapMarkers(): void {
        this.model.allMapPins = [];
        this.model.markers = [];
    }

    resetLatLongBounds(): void {
        this.model.latitudeLongitudeBounds = new google.maps.LatLngBounds();
    }

    createMap(mapElement: any): void {
        this.model.map = new google.maps.Map(mapElement, this.model.mapProperties);
        this.createMarkerCluster();
        this.setMapLoading(false);
    }

    fitMapToLatLongBoundsOfSite(site: Site): void {
        this.resetLatLongBounds();
        this.createLatLongBounds([site]);
        this.fitMapToLatLongBounds();
    }

    fitMapToLatLongBounds(): void {
        this.model.map.fitBounds(this.model.latitudeLongitudeBounds);
    }

    createLatLongBounds(sites: Site[]): void {
        sites.forEach((site: Site) => {
            if (site.siteType === SiteTypes.LineArea) {
                this.extendLatLongBoundsFromGeoJson(site);
                return;
            }

            if (!site.latitude || !site.longitude) return;

            const latlng: google.maps.LatLng = this.createGoogleLatLongFromSite(site);
            if (this.isValidBounds(latlng)) this.model.latitudeLongitudeBounds.extend(latlng);
        });
    }

    extendLatLongBoundsFromGeoJson(site: Site): void {
        if (!site.geoJson) return;
        const geoJsonFeature: GeoJsonFeature = site.geoJson;
        const geoType = geoJsonFeature.geometry.type;
        switch (geoType) {
            case GeoJsonFeatureType.Point:
                let geometry = geoJsonFeature.geometry as Point;
                this.extendLatLongBounds(geometry.coordinates);
                break;
            case GeoJsonFeatureType.MultiPoint:
            case GeoJsonFeatureType.LineString:
                geoJsonFeature.geometry.coordinates.forEach((coordinate) => {
                    this.extendLatLongBounds(coordinate);
                });
                break;
            case GeoJsonFeatureType.MultiLineString:
            case GeoJsonFeatureType.Polygon:
                geoJsonFeature.geometry.coordinates.forEach((coordinate) => {
                    coordinate.forEach((c) => {
                        this.extendLatLongBounds(c);
                    });
                });
                break;
            case GeoJsonFeatureType.MultiPolygon:
                geoJsonFeature.geometry.coordinates.forEach((coordinate) => {
                    coordinate.forEach((c) => {
                        c.forEach((cc) => {
                            this.extendLatLongBounds(cc);
                        });
                    });
                });
                break;
            default:
                break;
        }
    }

    extendLatLongBounds(coordinate: Position): void {
        const latLng = this.createGoogleLatLong(coordinate[1], coordinate[0]);
        if (this.isValidBounds(latLng)) this.model.latitudeLongitudeBounds.extend(latLng);
    }

    isValidBounds(latlng: google.maps.LatLng): boolean {
        return latlng && !isNaN(latlng.lat()) && !isNaN(latlng.lng());
    }

    createGoogleLatLongFromSite(site: Site): google.maps.LatLng {
        const latitude = site.latitude;
        const longitude = site.longitude;
        return this.createGoogleLatLong(latitude, longitude);
    }

    createGoogleLatLong(latitude: number, longitude: number): google.maps.LatLng {
        return new google.maps.LatLng(latitude, longitude);
    }

    createMarkerCluster(): void {
        const map = this.model.map;
        const imagePath = this.model.markerClusterImagePath;
        this.model.markerCluster = new MarkerClusterer(map, [], { imagePath: imagePath });
    }

    setMapLoading(isLoading: boolean): void {
        this.model.mapLoading = isLoading;
    }

    createMapPinsAndGeoJson(mapIcon: MapIcon, sites: Site[]): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                if (sites.length === 0) {
                    resolve();
                    return;
                }
                this.resetMapMarkers();
                this.resetLatLongBounds();
                this.clearGeoJsonFiles();
                for (let site of sites) {
                    if (site.siteType === SiteTypes.LineArea) {
                        if (!site.geoJson)
                            return;
                        if (site.geoJson.geometry.type !== GeoJsonFeatureType.Point)
                            this.loadGeoJsonFileOnMap(site);
                    }
                    const mapPin: MapPin = this.createMapPinFromSite(site, mapIcon);
                    this.model.allMapPins.push(mapPin);
                }
                this.model.filteredMapPins = [...this.model.allMapPins];
                this.addMarkersToMap();
                this.createLatLongBounds(sites);
                this._sitesAddedToMap.next(true);
                resolve();
            }
            catch (error) {
                reject(error);
            }
        });
    }

    addMarkersToMap(): void {
        this.clearMarkers();
        this.createMarkers();
        this.addMarkers();
    }

    createMarkers(): void {
        this.model.filteredMapPins.forEach((mapPin => {
            const marker: KHMapMarker = this.createMarker(mapPin);
            this.model.markers.push(marker);
            this.setMarkerAndMapPin(marker, mapPin);
        }));
    }

    createMarker(mapPin: MapPin): KHMapMarker {
        const googleLatLng: google.maps.LatLng = this.createGoogleLatLong(mapPin.latitude, mapPin.longitude);
        return new KHMapMarker(
            mapPin.id,
            {
                position: googleLatLng,
                label: "",
                map: this.model.map,
                icon: mapPin.icon?.url !== '' ? mapPin.icon : null,
                title: mapPin.label
            }
        );
    }

    setMarkerAndMapPin(marker: KHMapMarker, mapPin: MapPin): void {
        this.setMarker(marker);
        this.setMapPin(mapPin);
    }

    setMarker(marker: KHMapMarker): void {
        this.model.marker = marker;
    }

    setMapPin(mapPin: MapPin): void {
        this.model.mapPin = mapPin;
    }

    createInfoWindowContent(site: Site, siteAlias: string): HTMLElement {
        const title: string = site.siteName;
        const siteNo: string = site.siteNo;

        var boxText = document.createElement("div");
        boxText.classList.add('kh-hover-cursor-pointer');
        boxText.innerHTML = (site.active) ? this.generateActiveContent(title, siteNo, siteAlias) : this.generateInactiveContent(title, siteNo, siteAlias);
        return boxText;
    }

    generateActiveContent(title: string, siteNo: string, siteAlias: string): string {
        return `
            <span style="color:#353A40;font-size:larger;font-weight:500;">${title}</span>
            <br>
            <span style="color:#77787B;">${siteAlias} No: ${siteNo}</span>`;
    }

    generateInactiveContent(title: string, siteNo: string, siteAlias: string): string {
        return `
            <span style="color:#353A40;font-size:larger;font-weight:500;">${title}</span>
            <br>
            <span style="color:#77787B;">
                <span style="font-style:italic">(Inactive)</span><br>
                ${siteAlias} No: ${siteNo}
            </span>`;
    }

    clearMarkers(): void {
        if (this.model.markers) this.model.markers = [];
        if (this.model.markerCluster) this.model.markerCluster.clearMarkers();
    }

    addMarkers(): void {
        if (this.model.markerCluster) this.model.markerCluster.addMarkers(this.model.markers);
    }

    createMapPinFromSite(site: Site, mapIcon: MapIcon): MapPin {
        return new MapPin(mapIcon, site);
    }

    getMapFilterOptions(app: App, project: Project): void {
        this._mapFiltersDataService.getMapFilterMenuByProjectId(app, project)
            .subscribe({
                next: (mapFilterMenuOptions: MapFilterMenuOptionsModel) => {
                    if (mapFilterMenuOptions) {
                        mapFilterMenuOptions.allFields = mapFilterMenuOptions.selectedFields;
                        this.model.mapFilterVisible = true;
                        this.model.mapFilterMenuOptions = mapFilterMenuOptions;
                    }
                    else {
                        this.model.mapFilterVisible = false;
                    }
                },
                error: () => {
                    this._snackBar.open(`Error getting Map Filters`, `Close`, { duration: 5000 });
                }
            });
        return null;
    }

    adjustLatLongBounds(sites: Site[]): void {
        this.resetLatLongBounds();
        this.createLatLongBounds(sites);
        this.fitMapToLatLongBounds();
    }

    applyMapFilters(app: App, project: Project, mapFilterMenuOptions: MapFilterMenuOptionsModel, mapIcon: MapIcon, sites: Site[]): void {
        if (sites?.length === 0) return;
        this.clearGeoJsonFiles();

        if (mapFilterMenuOptions.selectedFields.length === 0) {
            if (sites.length > 0 || sites[0].siteType === SiteTypes.LineArea) {
                this.model.filteredMapPins = [...this.model.allMapPins];
                this.addMarkersToMap();
                this.addFilteredGeoJsonSitesToMap(sites.filter(site => site.siteType === SiteTypes.LineArea));
                this.adjustLatLongBounds(sites);
            }

            this._sitesAddedToMap.next(true);

            if (sites.length === 1 && sites[0].siteType !== SiteTypes.LineArea)
                this.oneSiteDefaultZoom();

            this._snackBar.open(`Map Filters Removed`, `Close`, { duration: 3000 });
            return;
        }
        this.model.mapLoading = true;
        this.getSitesByFilterValues(app, project, mapFilterMenuOptions, sites);
    }

    getSitesByFilterValues(app: App, project: Project, mapFilterMenuOptions: MapFilterMenuOptionsModel, sites: Site[]): void {
        this._mapFiltersDataService.getSitesByFilterValues(app, project, mapFilterMenuOptions)
            .subscribe({
                next: (filteredSitesIds: string[]) => {
                    let filteredSites = sites.filter(site => filteredSitesIds.includes(site.id));
                    this.model.filteredMapPins = [];
                    this.model.filteredMapPins = this.model.allMapPins.filter(maPin => filteredSitesIds.includes(maPin.id.toString()));

                    this.addMarkersToMap();
                    this.addFilteredGeoJsonSitesToMap(filteredSites.filter(site => site.siteType === SiteTypes.LineArea));
                    this._sitesAddedToMap.next(true);

                    if (filteredSites.length > 0) {
                        this.adjustLatLongBounds(filteredSites);
                    }

                    if (filteredSitesIds.length === 1) {
                        if (filteredSites[0].siteType !== SiteTypes.LineArea)
                            this.oneSiteDefaultZoom();
                        this._snackBar.open(`Map Filters Applied. 1 ${app.aliases.site.singular} Found!`, `Close`, { duration: 3000 });
                        return;
                    }

                    this._snackBar.open(`Map Filters Applied. ${filteredSitesIds.length} ${app.aliases.site.plural} Found!`, `Close`, { duration: 3000 });
                },
                error: () => {
                    this._snackBar.open(`Error Applying Map Filters`, `Close`, { duration: 5000 });
                },
                complete: () => {
                    this.model.mapLoading = false;
                }
            });
    }

    oneSiteDefaultZoom(): void {
        this.model.map.setZoom(18);
    }

    loadGeoJsonFileOnMap(site: Site): void {
        let geoJsonFeature: GeoJsonFeature = site.geoJson;
        geoJsonFeature.properties.siteId = site.id;

        let dataLayer = new google.maps.Data();
        dataLayer.addGeoJson(geoJsonFeature);
        dataLayer.setStyle(this.getGeoJsonStyle);
        dataLayer.setMap(this.model.map);

        this.model.dataLayersMap.set(site.id, dataLayer);
    }

    getGeoJsonStyle(feature: google.maps.Data.Feature): google.maps.Data.StyleOptions {
        return {
            title: feature.getProperty('siteId'),
            cursor: 'pointer',
            clickable: true,
            fillColor: feature.getProperty('fill') ?? feature.getProperty('fillColor'),
            fillOpacity: feature.getProperty('fill-opacity') ?? feature.getProperty('fillOpacity'),
            strokeColor: feature.getProperty('stroke') ?? feature.getProperty('strokeColor'),
            strokeOpacity: feature.getProperty('stroke-opacity') ?? feature.getProperty('strokeOpacity'),
            strokeWeight: feature.getProperty('stroke-width') ?? feature.getProperty('strokeWeight'),
            zIndex: feature.getProperty('zIndex') ?? feature.getProperty('layer'),
        };
    }

    clearGeoJsonFiles(): void {
        this.model.dataLayersMap.forEach((dataLayer) => {
            dataLayer.setMap(null);
        });
        this.model.dataLayersMap.clear();
    }

    addFilteredGeoJsonSitesToMap(sites: Site[]): void {
        sites.forEach((site) => {
            if (!site.geoJson) return;
            this.loadGeoJsonFileOnMap(site);
        });
    }

    openFilterMenu(): void {
        this.model.expandFilterMenu = true;
    }

    closeFilterMenu(): void {
        this.model.expandFilterMenu = false;
    }

    setMapTypeProperty(): void {
        this.model.mapProperties.mapTypeId = this.mapTypeRoadMap;
    }

    togglePOI(): void {
        var newVisibility = (!this.model.mapLayersForm.value.pointsOfInterest) ? 'on' : 'off';

        var updatedStyles = this.model.map.get('styles').map((style) => {
            if (style.featureType === 'poi') {
                style.elementType = 'labels';
                style.stylers[0].visibility = newVisibility;
            }
            return style;
        });

        this.model.map.set('styles', updatedStyles);
    }

    toggleRoadNames(): void {
        var newVisibility = (!this.model.mapLayersForm.value.roadNames) ? 'on' : 'off';
        var updatedStyles = this.model.map.get('styles').map((style) => {
            if (style.featureType === 'road') {
                style.elementType = 'labels';
                style.stylers[0].visibility = newVisibility;
            }
            return style;
        });

        this.model.map.set('styles', updatedStyles);
    }

    switchMapTypeToRoadMap(): void {
        this.model.map.setMapTypeId(this.mapTypeRoadMap);
    }

    switchMapTypeToSatellite(): void {
        this.model.map.setMapTypeId(this.mapTypeSatellite);
    }

    switchMapTypeToTerrain(): void {
        this.model.map.setMapTypeId(this.mapTypeTerrain);
    }

    addMouseOutListenerForMarker(marker: KHMapMarker): void {
        google.maps.event.addListener(marker, 'mouseout', (event) => {
            //class for the bottom of the info window
            if (event.domEvent.toElement?.className === 'gm-style-iw-tc') return;
            this.closeInfoWindow();
        });
    }

    addMouseOutEventToDataLayer(dataLayer: google.maps.Data): void {
        dataLayer.addListener('mouseout', () => {
            this.closeInfoWindow();
        });
    }

    addCloseInfoWindowEvent(): void {
        google.maps.event.addListener(this.model.infoWindow, 'closeclick', () => {
            this.closeInfoWindow();
        });
    }

    addMouseOutInfoWindowEvent(): void {
        google.maps.event.addListener(this.model.infoWindow, 'domready', () => {
            document.querySelector('.gm-style-iw.gm-style-iw-c').addEventListener('mouseleave', () => {
                this.closeInfoWindow();
            });
        });
    }

    closeInfoWindow(): void {
        google.maps.event.clearListeners(this.model.currentInfoWindowContent, 'click');
        this.model.infoWindow.close();
    }

    clearInfoWindowListeners(): void {
        google.maps.event.clearListeners(this.model.infoWindow, 'domready');
        google.maps.event.clearListeners(this.model.infoWindow, 'closeclick');
    }

}
