<div class="kh-file-select-container">

    <div class="kh-file-select-input-row">
        <kendo-fileselect
            (select)="fileSelected($event)"
            [(ngModel)]="selectedFiles"
            [accept]="acceptedFileTypes"
            [multiple]="false"
            [restrictions]="uploadRestrictions"
            *ngIf="isEditable"
            class="kh-file-select"
        >
            <kendo-fileselect-messages
                class="kh-text-color"
                dropFilesHere="Drop file here"
                select="Select/drop file"
            >
            </kendo-fileselect-messages>
        </kendo-fileselect>
    </div>

    <kh-files-template
        (deleteFileEventEmitter)="deleteFile($event)"
        (downloadFileEventEmitter)="downloadFile($event)"
        (previewFileEventEmitter)="previewFile($event)"
        [isDownloadable]="isDownloadable"
        [isEditable]="isEditable"
        [isLoading]="isLoading"
        [isPreviewable]="isPreviewable"
        [selectedFiles]="selectedFiles"
        *ngIf="!hasError && selectedFiles?.length"
    ></kh-files-template>

</div>

<div *ngIf="showGuideMessage()" class="kh-guide-message">
    {{ guideMessage }}
</div>

<div *ngIf="showErrorMessage()"
    class="kh-error-message">
    <div class="kh-error-small">
        <span class="kh-error-icon material-symbols-outlined">
            error
        </span>

        <span class="kh-error-text">
           {{ errorMessage }}
        </span>
    </div>
</div>
