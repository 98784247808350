/* istanbul ignore file */
import { State } from '@progress/kendo-data-query';

import { ReportViewGridColumnProperties } from './report-view-grid-column-properties';

export class ReportView {
    id: string;
    name: string;
    columnSettings: ReportViewGridColumnProperties[];
    kendoGridState: State;
    reportDefinitionId: string;
}

export enum SaveReportViewButtonStateEnum {
    SaveOrUpdateCurrent = 'saveOrUpdateCurrent',
    None = 'none',
    SaveFromDefault = 'saveFromDefault',

}
