<div class="kh-files-template">
    <ng-container *ngFor="let file of selectedFiles">
        <div class="kh-file-template">
            <span
                class="kh-file-icon k-icon"
                [ngClass]="getFileIconFromFileInfo(file)"
            ></span>

            <span class="kh-file-info">
                <span>
                    <span class="kh-file-name">
                    {{ file.name }}
                    </span>
                    <span class="kh-file-size">
                        {{ getFileSize(file.size) }}
                    </span>
                </span>

                <span *ngIf="isLoading">
                    <mat-spinner
                        class="kh-spinner"
                        diameter="20"
                    ></mat-spinner>
                </span>
            </span>

            <span class="kh-flex-end">

                <ng-container *ngIf="showMenuTogglesForFile(file); else singleControlTemplate">
                    <button
                        mat-icon-button
                        [matMenuTriggerFor]="fileActionsMenu"
                        (click)="setSelectedFileForMenuActions(file)"
                        class="kh-button-icon"
                    >
                        <mat-icon
                            aria-hidden="false"
                            aria-label="more_horiz icon"
                        >
                            more_horiz
                        </mat-icon>
                    </button>
                </ng-container>

                <ng-template #singleControlTemplate>
                    <ng-container *ngIf="isEditable; else viewAndDownloadTemplate">
                        <button
                            (click)="deleteFile(file)"
                            class="kh-button-icon"
                            mat-icon-button
                        >
                            <mat-icon
                                aria-hidden="false"
                                aria-label="delete icon"
                            >
                                delete
                            </mat-icon>
                        </button>
                    </ng-container>

                    <ng-template #viewAndDownloadTemplate>
                        <ng-container *ngIf="isDownloadable; else previewTemplate">
                            <button
                                (click)="downloadFile(file)"
                                class="kh-button-icon"
                                mat-icon-button
                            >
                                <mat-icon
                                    aria-hidden="false"
                                    aria-label="download icon"
                                >
                                    download
                                </mat-icon>
                            </button>
                        </ng-container>

                        <ng-template>
                            <button
                                (click)="previewFile(file)"
                                class="kh-button-icon"
                                mat-icon-button
                            >
                                <mat-icon
                                    aria-hidden="false"
                                    aria-label="preview icon"
                                >
                                    remove_red_eye
                                </mat-icon>
                            </button>
                        </ng-template>
                    </ng-template>
                </ng-template>

            </span>

        </div>
    </ng-container>
</div>

<mat-menu
    #fileActionsMenu="matMenu"
    xPosition="before"
    yPosition="below"
>
    <div
        (click)="downloadFile(selectedFile)"
        *ngIf="isDownloadable"
        class="kh-menu-item"
        mat-menu-item
    >
        <mat-icon
            aria-hidden="false"
            aria-label="download icon"
        >
            download
        </mat-icon>
        <span>Download</span>
    </div>

    <div
        (click)="previewFile(selectedFile)"
        *ngIf="isPreviewable && isFileTypePreviewable(selectedFile)"
        class="kh-menu-item"
        mat-menu-item
    >
        <mat-icon
            aria-hidden="false"
            aria-label="preview icon"
        >
            remove_red_eye
        </mat-icon>

        <span>Preview</span>
    </div>

    <div
        (click)="deleteFile(selectedFile)"
        *ngIf="isEditable"
        class="kh-menu-item"
        mat-menu-item
    >
        <mat-icon
            aria-hidden="false"
            aria-label="delete icon"
        >
            delete
        </mat-icon>
        <span>Delete file</span>
    </div>
</mat-menu>

