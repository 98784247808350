import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';

import { GridColumnProperties } from '../grid-column-properties';

/* istanbul ignore file */
export class ReportFormattingData {
    columnSettings: GridColumnProperties[] = [];
    filters: (CompositeFilterDescriptor | FilterDescriptor)[] = [];
    hiddenColumns: Map<string, boolean>;
    lockedColumns: Map<string, boolean>;
}
