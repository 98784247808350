/* istanbul ignore file */
export enum LicenseTiers {
    PerSite = 0,
    Bronze = 1,
    Silver = 2,
    Gold = 3,
    Platinum = 4,
    Diamond = 5,
    Titanium = 6,
    Other = 7,
}

export enum LicenseTiersName {
    PerSite = "Per Site",
    Bronze = "Bronze",
    Silver = "Silver",
    Gold = "Gold",
    Platinum = "Platinum",
    Diamond = "Diamond",
    Titanium = "Titanium",
    Other = "Other",
}
