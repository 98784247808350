/* istanbul ignore file */

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GridDataResult } from '@progress/kendo-angular-grid';

import { Site } from '../../../../../models';
import { AppsService } from '../../../../../services';
import { AppAliasService } from '../../../../../services/logic-services';

@Component({
    selector: 'kh-excel-bulk-site-upload-duplicate-sites-dialog',
    templateUrl: './excel-bulk-site-upload-duplicate-sites-dialog.component.html',
    styleUrls: ['./excel-bulk-site-upload-duplicate-sites-dialog.component.scss']
})
export class ExcelBulkSiteUploadDuplicateSitesDialogComponent implements OnInit {
    gridData: GridDataResult;
    siteNumberTitle = `${this.siteAlias} number`;

    get sitesAliasLowerCase(): string { return this._appAliasService.sitesAliasLowerCase; }
    get siteAliasLowerCase(): string { return this._appAliasService.siteAliasLowerCase; }

    get siteAlias(): string {
        return this._appsService.currentApp.aliases.site.singular;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Site[],
        private _appAliasService: AppAliasService,
        private _appsService: AppsService,
        public dialogRefDuplicate: MatDialogRef<ExcelBulkSiteUploadDuplicateSitesDialogComponent>,
    ) { }
    ngOnInit(): void {
        this.loadData();
    }

    loadData(): void {
        this.gridData = {
            data: this.data,
            total: this.data.length
        };
    }

    closeDialog(): void {
        this.dialogRefDuplicate.close(false);
    }

    onConfirm(): void {
        this.dialogRefDuplicate.close(true);
    }
}
