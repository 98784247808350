import { CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as _ from 'lodash';

import { FieldsLogicService, ReportDefinitionGridsLogicService } from '.';
import { App, Project } from '../../models';
import { MapFilterMenuOptionsModel } from '../../models/map-filter-menu-options-model';
import { MapFilterModel } from '../../models/map-filter-model';
import { FieldType } from '../../shared/enums';
import { MapFiltersDataService } from '../data-services';
import { Field, MapFilterSelectionModel } from './models';

@Injectable({
    providedIn: 'root'
})

export class MapFiltersSelectionLogicService {

    model: MapFilterSelectionModel;

    get mapFiltersForm(): FormGroup {
        return this.model.mapFiltersForm;
    }

    get availableFields(): Field[] {
        return this.model.availableFields;
    }

    set availableFields(inAvailableFields: Field[]) {
        this.model.availableFields = inAvailableFields;
    }

    get allFields(): Field[] {
        return this.model.mapFilterMenuOptions.allFields;
    }

    get selectedFields(): Field[] {
        return this.model.mapFilterMenuOptions.selectedFields;
    }

    set selectedFields(inSelectFields: Field[]) {
        if (inSelectFields?.length) {
            this.model.mapFilterMenuOptions.selectedFields = [...inSelectFields];
            if (this.allFields?.length) {
                this.model.availableFields = this._reportDefinitionGridLogicService.removeDuplicateFields(this.model.mapFilterMenuOptions.selectedFields, this.allFields);
                this.model.availableFields = this._reportDefinitionGridLogicService.removeGridFieldsFromAvailableFields(this.model.mapFilterMenuOptions.selectedFields, this.model.availableFields);
            }
            else {
                this.model.availableFields = [];
            }
        }
        else {
            this.model.availableFields = [...this.allFields];
            this.model.mapFilterMenuOptions.selectedFields = [];
        }

        this.model.mapFiltersForm.patchValue({
            selectedFields: inSelectFields,
        });
    }

    constructor(
        private _formBuilder: FormBuilder,
        private _fieldsLogicService: FieldsLogicService,
        private _reportDefinitionGridLogicService: ReportDefinitionGridsLogicService,
        private _mapFiltersDataService: MapFiltersDataService,
        private _snackBar: MatSnackBar,
    ) { }

    initModel(): void {
        this.model = new MapFilterSelectionModel();
        this.model.id = null;
        this.model.projectId = null;
        this.model.mapFilterMenuOptions = new MapFilterMenuOptionsModel();
        this.model.mapFilterMenuOptions.selectedFields = Array<Field>();
        this.model.availableFields = Array<Field>();
        this.model.mapFilterMenuOptions.allFields = Array<Field>();
        this.model.mapFiltersForm = this._formBuilder.group({
            id: new FormControl(),
            projectId: new FormControl(),
            selectedFields: ([])
        });
    }

    loadModel(incomingFilter: MapFilterModel): void {
        this.model.id = incomingFilter.id;
        this.model.projectId = incomingFilter.projectId;
        this.selectedFields = incomingFilter.selectedFields;
    }

    loadForm(): void {
        this.model.mapFiltersForm.patchValue({
            id: this.model.id,
            projectId: this.model.projectId,
            selectedFields: this.model.mapFilterMenuOptions.selectedFields
        });
    }

    getMapFilterByProjectId(app: App, project: Project): void {
        if (app.id == this.model.previousApp?.id && project.id == this.model.previousProject?.id) {
            return;
        }
        this.model.previousApp = app;
        this.model.previousProject = project;
        this._mapFiltersDataService.getMapFilterByProjectId(app, project)
            .subscribe({
                next: (mapFilter: MapFilterModel) => {
                    if (mapFilter != null) {
                        let unfilteredFields = this._fieldsLogicService.getGridFieldsToFields(project);
                        this.setAllFields(this.filterFieldsByMapFilter(unfilteredFields), app.aliases.site.singular);
                        this.loadModel(mapFilter);
                        this.loadForm();
                        this.getMapFilterValuesByProjectId(app, project, this.allFields);
                        this._snackBar.open(`Map Filters loaded`, `Close`, { duration: 5000 });
                    }
                    else {
                        this.initModel();
                        this.model.projectId = project.id;
                        this.selectedFields = new Array<Field>();
                        let unfilteredFields = this._fieldsLogicService.getGridFieldsToFields(project);
                        this.setAllFields(this.filterFieldsByMapFilter(unfilteredFields), app.aliases.site.singular);
                        this.getMapFilterValuesByProjectId(app, project, this.allFields);
                        this.mapFiltersForm.patchValue({
                            projectId: project.id,
                        });
                    }
                },
                error: () => {
                    this._snackBar.open(`Error getting Map Filters`, `Close`, { duration: 5000 });
                }
            });
    }

    getMapFilterValuesByProjectId(app: App, project: Project, allFields: Field[]): void {
        this._mapFiltersDataService.getMapFilterValuesByProjectId(app, project, allFields)
            .subscribe({
                next: (mapFilterMenuOptions: MapFilterMenuOptionsModel) => {
                    if (mapFilterMenuOptions) {
                        this.model.mapFilterMenuOptions = { ...this.model.mapFilterMenuOptions, filterValues: mapFilterMenuOptions.filterValues };
                    }
                },
                error: () => {
                    this._snackBar.open(`Error getting Map Filter Values`, `Close`, { duration: 5000 });
                }
            });
    }
    saveMapFilters(app: App, project: Project, mapFilter: FormGroup): void {
        this._mapFiltersDataService.saveMapFilters(app, project, mapFilter)
            .subscribe({
                next: () => {
                    this._snackBar.open(`Map Filters Saved`, `Close`, { duration: 5000 });
                },
                error: () => {
                    this._snackBar.open(`Error saving Map Filters`, `Close`, { duration: 5000 });
                }
            });
    }

    filterFieldsByMapFilter(unfilteredFields: Field[]): Field[] {
        if (!unfilteredFields)
            unfilteredFields = new Array<Field>();

        const filteredFields: Field[] = [];

        unfilteredFields.forEach(field => {
            if (field.FieldType.id == FieldType.CheckBox || field.FieldType.id == FieldType.DropDown || field.FieldType.id == FieldType.Number || field.FieldType.id == FieldType.DateSelect) {
                filteredFields.push(field);
            }
        });

        return filteredFields;
    }

    setDefaultFilters(allIncomingFields: Field[], siteAlias: string): Field[] {
        if (!allIncomingFields)
            allIncomingFields = new Array<Field>();

        const Field2: Field = { id: "4", Name: `${siteAlias} City`, Alias: "", breadcrumb: "", FieldType: { id: `1`, text: 'Text Box', required: null, type: 'string' } };
        const Field3: Field = { id: "5", Name: `${siteAlias} State`, Alias: "", breadcrumb: "", FieldType: { id: `1`, text: 'Text Box', required: null, type: 'string' } };
        const Field5: Field = { id: "7", Name: `${siteAlias} Status`, Alias: "", breadcrumb: "", FieldType: { id: `1`, text: 'Checkbox', required: null, type: 'boolean' } };

        allIncomingFields.sort((a, b) => (a.Name > b.Name) ? 1 : -1);
        allIncomingFields.unshift(Field5);
        allIncomingFields.unshift(Field3);
        allIncomingFields.unshift(Field2);

        return allIncomingFields;
    }

    dropAvailable(event: CdkDragDrop<string[]>, selectedFieldsList: CdkDropList<string[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        }
        else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        }

        this.model.availableFields = this._reportDefinitionGridLogicService.returnGridFieldsToAvailableFields(this.model.mapFilterMenuOptions.selectedFields, this.model.mapFilterMenuOptions.allFields);
        this.model.availableFields = this._reportDefinitionGridLogicService.removeDuplicateFields(this.model.mapFilterMenuOptions.selectedFields, this.allFields);
        this.model.availableFields = this._reportDefinitionGridLogicService.removeGridFieldsFromAvailableFields(this.model.mapFilterMenuOptions.selectedFields, this.model.availableFields);

        this.model.mapFiltersForm.patchValue({
            selectedFields: selectedFieldsList.data,
        });
    }

    dropSelected(event: CdkDragDrop<string[]>, selectedFieldsList: CdkDropList<string[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        }
        else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        }

        this.model.availableFields = this._reportDefinitionGridLogicService.removeDuplicateFields(this.model.mapFilterMenuOptions.selectedFields, this.allFields);
        this.model.availableFields = this._reportDefinitionGridLogicService.removeGridFieldsFromAvailableFields(this.model.mapFilterMenuOptions.selectedFields, this.model.availableFields);

        this.model.mapFiltersForm.patchValue({
            selectedFields: selectedFieldsList.data,
        });
    }

    fieldFilterChange(filterString: string): void {
        this.resetAvailableFields();
        this.model.availableFields = this.model.availableFields.filter((field) => {
            return field.Name.toLowerCase().includes(filterString.toLowerCase());
        });
    }

    resetAvailableFields(): void {
        if (this.model.mapFilterMenuOptions.selectedFields?.length) {
            if (this.allFields?.length) {
                const allFieldsByIsSelected = _.chain(this.allFields)
                    .groupBy((f) => _.some(this.model.mapFilterMenuOptions.selectedFields, (sf) => sf.Name === f.Name))
                    .value();
                this.model.availableFields = allFieldsByIsSelected[false] || [];
            }
            else {
                this.model.availableFields = [];
            }
        }
        else {
            this.model.availableFields = _.chain(this.allFields).orderBy('Name').value();
        }
    }

    unselectAll(): void {
        this.selectedFields = [];
        this.model.availableFields = [...this.allFields];
    }

    saveFilter(currentApp: App, currentProject: Project): void {
        this.saveMapFilters(currentApp, currentProject, this.model.mapFiltersForm.value);
    }

    setAllFields(allIncomingFields: Field[], siteAlias: string): void {
        allIncomingFields = this.setDefaultFilters(allIncomingFields, siteAlias);

        this.model.mapFilterMenuOptions.allFields = allIncomingFields;
        this.model.mapFilterMenuOptions = { ...this.model.mapFilterMenuOptions };
        this.selectedFields = _.intersectionBy(
            this.selectedFields,
            this.model.mapFilterMenuOptions.allFields,
            'id'
        );
    }
}
