import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { FileInfo, SelectEvent } from '@progress/kendo-angular-upload';

import { User } from '../../../models';
import { UsersService } from '../../../services';
import { HelpFeedbackServiceModel } from '../../../services/help-feedback-service-model';
import { HelpFeedbackLogicService } from '../../../services/logic-services/help-feedback-logic.service';
import { ContactMethod } from '../../../shared/enums/contact-method.enum';
import { FormStatus } from '../../../shared/enums/form-status.enum';

@Component({
    selector: 'kh-feedback-form',
    templateUrl: './feedback-form.component.html',
    styleUrls: ['./feedback-form.component.scss']
})
export class FeedbackFormComponent implements OnInit {

    contactOptions = [
        { name: "N/A", value: ContactMethod.noContact },
        { name: "Email", value: ContactMethod.email },
        { name: "Phone", value: ContactMethod.phone }
    ];
    contactMethod: ContactMethod = ContactMethod.empty;
    description: string;
    feedBackData: FormData;
    feedBackForm: FormGroup;
    files = [];
    formStep: FormStatus;

    get confirmationNumber(): string {
        return this.model.serviceResponse.reference;
    }

    get model(): HelpFeedbackServiceModel {
        return this._helpFeedbackService.model;
    }

    get user(): User {
        return this._usersService.currentUser;
    }

    constructor(
        private _helpFeedbackService: HelpFeedbackLogicService,
        private _snackBar: MatSnackBar,
        private _usersService: UsersService,
    ) { }

    ngOnInit(): void {
        this.feedBackForm = this._helpFeedbackService.createFormGroup(this.feedBackForm);
        this.formStep = FormStatus.inProgress;
        this._helpFeedbackService.initModel();
    }

    closeDialog(): void {
        this._helpFeedbackService.model.showForm = false;
    }

    isFormValid(): boolean {
        if (this.feedBackForm.status == "INVALID") {
            this._snackBar.open(`Please fill in all the fields`, "Close", { duration: 5000 });
            return false;
        }
        return true;
    }

    submitForm(): void {
        this.feedBackForm.markAllAsTouched();
        if (this.isFormValid()) {
            this.feedBackData = this._helpFeedbackService.createFormData(this.feedBackForm, this.files, this.user);
            this._helpFeedbackService.createServiceRequest(this.feedBackData);
            this.formStep = FormStatus.completed;
        }
    }

    clearPhoneValidators(): void {
        this.feedBackForm.value.phone = null;
        this.feedBackForm.controls['phone'].clearValidators();
        this.feedBackForm.controls['phone'].updateValueAndValidity();
    }

    addPhoneValidators(): void {
        this.feedBackForm.controls['phone'].addValidators([Validators.required, Validators.pattern('[- +()0-9]{10,12}')]);
        this.feedBackForm.controls['phone'].updateValueAndValidity();
    }

    setPhoneValidators(): void {
        if (this.contactMethod === 'Phone') {
            this.addPhoneValidators();
        }
        else {
            this.clearPhoneValidators();
        }
    }

    setContactMethod(contactMethod: ContactMethod): void {
        this.contactMethod = contactMethod;
        this.setPhoneValidators();
        this.feedBackForm.controls.contactMethod.setValue(contactMethod);
    }

    selectFile(event: SelectEvent): void {
        this.files = [];
        if (event.files.length > 0) {
            event.files.forEach((file: FileInfo) => {
                this.files.push(file);
            });
        }
    }

    removeFile(event: File): void {
        this.files = this.files.filter(item => item.name !== event.name);
    }
}
