import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Subject } from 'rxjs';

import { App, Invoice, InvoiceGridViewRow } from '../../models';
import { LicenseTiersName } from '../../shared/enums';
import { InvoiceDataService } from '../data-services';
import { DateConversionService } from '../utility-services';
import { InvoiceLogicServiceModel } from './models';

@Injectable({
    providedIn: 'root'
})
export class InvoiceLogicService {
    private _invoicesLoaded = new Subject<boolean>();
    private _invoicesLoading = new Subject<boolean>();

    model = new InvoiceLogicServiceModel();

    invoicesLoaded$ = this._invoicesLoaded.asObservable();
    invoicesLoading$ = this._invoicesLoading.asObservable();

    constructor(
        private _snackBar: MatSnackBar,
        private _invoiceData: InvoiceDataService,
        private _dateConversion: DateConversionService
    ) { }

    getYearMonthInvoicesByAppId(app: App, year: string, month: string): void {
        this._invoicesLoading.next(true);

        this._invoiceData.getYearMonthInvoicesByAppId(app, year, month)
            .subscribe({
                next: (invoice: Invoice) => {
                    this.addOrUpdateInvoices([invoice]);
                },
                error: () => {
                    this._snackBar.open('Error getting invoices', '', {
                        duration: 2000,
                    });
                }
            }).add(() => {
                this._invoicesLoading.next(false);
                this._invoicesLoaded.next(true);
            });
    }

    getAllInvoicesForYear(app: App, year: string): void {
        this._invoicesLoading.next(true);

        this._invoiceData.getAllInvoicesForYear(app, year)
            .subscribe({
                next: (invoices: Invoice[]) => {
                    this.addOrUpdateInvoices(invoices);
                },
                error: () => {
                    this._snackBar.open('Error getting invoices', '', {
                        duration: 2000,
                    });
                }
            }).add(() => {
                this._invoicesLoading.next(false);
                this._invoicesLoaded.next(true);
            });
    }

    getAllMonthInvoicesForYearAllApps(year: string): void {
        this._invoicesLoading.next(true);

        this._invoiceData.getAllMonthInvoicesForYearAllApps(year)
            .subscribe({
                next: (invoices: Invoice[]) => {
                    this.addOrUpdateInvoices(invoices);
                },
                error: () => {
                    this._snackBar.open('Error getting invoices', '', {
                        duration: 2000,
                    });
                }
            }).add(() => {
                this._invoicesLoading.next(false);
                this._invoicesLoaded.next(true);
            });
    }

    getYearMonthInvoicesForAllApps(year: string, month: string): void {
        this._invoicesLoading.next(true);

        this._invoiceData.getYearMonthInvoicesForAllApps(year, month)
            .subscribe({
                next: (invoices: Invoice[]) => {
                    this.addOrUpdateInvoices(invoices);
                },
                error: () => {
                    this._snackBar.open('Error getting invoices', '', {
                        duration: 2000,
                    });
                }
            }).add(() => {
                this._invoicesLoading.next(false);
                this._invoicesLoaded.next(true);
            });
    }

    addOrUpdateInvoices(invoicesFetched: Invoice[]): void {
        if (!invoicesFetched.length)
            return;

        invoicesFetched.forEach(invoiceFetched => {
            const index = this.model.invoices.findIndex(invoice => invoice?.id === invoiceFetched.id);
            if (index !== -1) {
                this.model.invoices[index] = invoiceFetched;
            }
            else {
                this.model.invoices.push(invoiceFetched);
            }
        });
    }

    processInvoices(): void {
        this._invoicesLoading.next(true);
        this._invoiceData.processInvoices()
            .subscribe({
                next: () => {
                    this._snackBar.open('Invoices process started. Wait for email report', '', {
                        duration: 2000,
                    });
                },
                error: () => {
                    this._snackBar.open('Error processing invoices', '', {
                        duration: 2000,
                    });
                }
            }).add(() => {
                this._invoicesLoading.next(false);
            });
    }

    resetInvoices(): void {
        this._invoicesLoading.next(true);
        this._invoiceData.resetInvoices()
            .subscribe({
                next: () => {
                    this._snackBar.open('Invoices resetting started', '', {
                        duration: 2000,
                    });
                },
                error: () => {
                    this._snackBar.open('Error resetting invoices', '', {
                        duration: 2000,
                    });
                }
            }).add(() => {
                this._invoicesLoading.next(false);
            });
    }

    getRowsFromYearMonth(selectedApp: App, year: string, month: string): InvoiceGridViewRow[] {
        let filteredInvoices: Invoice[];

        if (month == "-1")
            filteredInvoices = this.model.invoices.filter(invoice => invoice.year === year.toString());
        else
            filteredInvoices = this.model.invoices.filter(invoice => invoice.year === year.toString() && invoice.month === month.toString());

        let combinedData: InvoiceGridViewRow[] = filteredInvoices.reduce((rows: InvoiceGridViewRow[], invoice: Invoice) => {
            if (invoice.sites) {
                const transformedSites: InvoiceGridViewRow[] = invoice.sites.map(site => (
                    {
                        appName: site.appName,
                        licenseType: LicenseTiersName.PerSite,
                        month: invoice.month,
                        year: invoice.year,
                        siteNo: site.siteNo,
                        siteName: site.siteName,
                        chargeNumber: site.chargeNumber,
                        startDate: site.startDate,
                        endDate: site.endDate,
                        createdOn: this._dateConversion.convertDateToString(invoice.dateCreated)
                    }));
                rows = rows.concat(transformedSites);
            }
            else if (invoice.invoicedEnterpriseApp) {
                const transformedApp: InvoiceGridViewRow = {
                    appName: invoice.invoicedEnterpriseApp.appName,
                    licenseType: invoice.invoicedEnterpriseApp.invoicedLicenseTier.name,
                    month: invoice.month,
                    year: invoice.year,
                    siteNo: "",
                    siteName: "",
                    chargeNumber: invoice.invoicedEnterpriseApp.chargeNumber,
                    startDate: invoice.invoicedEnterpriseApp.startDate,
                    endDate: invoice.invoicedEnterpriseApp.endDate,
                    createdOn: this._dateConversion.convertDateToString(invoice.dateCreated)
                };
                rows.push(transformedApp);
            }
            return rows;
        }, []);

        if (selectedApp.id !== "-1")
            combinedData = combinedData.filter(row => row.appName.trimEnd() == selectedApp.name);

        return combinedData;
    }
}
