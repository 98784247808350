import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';

import { Subject } from 'rxjs';

import { App, DynamicLogicSet, Project } from '../../models';
import { ReportViewGridColumnProperties } from '../../models/report-view-grid-column-properties';
import { ReportView } from '../../models/report-view-model';
import { Field } from '../../services/logic-services/models/field';
import { ReportsDataService, ReportsDefinitionDataService } from '../data-services';
import { GridColumnProperties, ReportViewMatDialogData } from './models';
import { Report } from './models/report/report';
import { ReportDefinitionHeader } from './models/report/report-definition-header';
import { ReportFormattingData } from './models/report/report-formatting-data';
import { ReportsServiceModel } from './models/report/reports-service-model';

@Injectable({
    providedIn: 'root',
})
export class ReportsLogicService {

    private reportDefinitionsHeadersChanged = new Subject<boolean>();
    private reportFormattingDataSet = new Subject<boolean>();
    private reportViewCreated = new Subject<ReportView>();

    model: ReportsServiceModel;
    reportDefinitionsHeadersChanged$ = this.reportDefinitionsHeadersChanged.asObservable();
    reportFormattingDataSet$ = this.reportFormattingDataSet.asObservable();
    reportViewCreated$ = this.reportViewCreated.asObservable();

    pageSizes = [50, 100, 150];
    pageSize = this.pageSizes[0];

    defaultViewId = 'default';
    defaultView: ReportView = {
        kendoGridState: {
            skip: 0,
            take: this.pageSize,
            sort: [] as SortDescriptor[],
            filter: {
                logic: 'and',
                filters: [] as FilterDescriptor[]
            }
        },
        id: this.defaultViewId,
        name: 'Default',
        columnSettings: [],
        reportDefinitionId: '',
    };

    constructor(
        private _reportDataService: ReportsDataService,
        private _reportDefinitionDataService: ReportsDefinitionDataService,
        private _snackBar: MatSnackBar
    ) { }

    initModel(): void {
        if (this.model === undefined) {
            this.model = new ReportsServiceModel();
            this.model.applicableConditionalLogic = new Array<DynamicLogicSet>();
            this.model.reportDefinitionsHeaders = new Array<ReportDefinitionHeader>();
            this.model.reportLoading = false;
            this.model.reportsMap = new Map<string, Report>();
            this.model.reportFormatData = new ReportFormattingData();
            this.model.reportFormatData.columnSettings = new Array<GridColumnProperties>();
            this.model.reportFormatData.hiddenColumns = new Map<string, boolean>();
            this.model.reportFormatDatasMap = new Map<string, ReportFormattingData>();
        }
    }

    getReportDefinitionsHeaders(app: App, project: Project): void {
        this._reportDataService.getReportDefinitionsHeaders(app, project)
            .subscribe({
                next: (result: ReportDefinitionHeader[]) => {
                    this.model.reportDefinitionsHeaders = result;
                    this.reportDefinitionsHeadersChanged.next(true);
                },
                error: () => {
                    this._snackBar.open('Error getting reports', 'Close', { duration: 2000 });
                },
                complete: () => { },
            })
            .add(() => { });
    }

    getReportByReportDefinitionId(app: App, project: Project, reportDefinitionId: string): void {
        if (!this.model.reportsMap.has(reportDefinitionId)) {
            this.model.reportLoading = true;
            this._reportDataService
                .getReportByReportDefinitionId(app, project, reportDefinitionId)
                .subscribe({
                    next: (result: Report) => {
                        this.model.reportsMap.set(result.reportDefinitionId, result);
                        if (result.columnSettings?.length)
                            this.model.reportFormatData.columnSettings = result.columnSettings;
                    },
                    error: () => {
                        this._snackBar.open('Error getting report', 'Close', { duration: 2000 });
                    },
                    complete: () => { },
                })
                .add(() => {
                    this.model.reportLoading = false;
                    this.getCurrentReportFormattingData(reportDefinitionId);
                });
        }
        this.getCurrentReportFormattingData(reportDefinitionId);
    }

    getCurrentReportFormattingData(reportDefinitionId: string): void {
        if (reportDefinitionId === undefined || reportDefinitionId === null) return;
        if (this.model.reportsMap.has(reportDefinitionId) && this.model.reportFormatDatasMap.has(reportDefinitionId)) {
            this.model.reportFormatData = this.model.reportFormatDatasMap.get(reportDefinitionId);
            this.reportFormattingDataSet.next(true);
            this.updateDefaultViewFromReport(this.model.reportsMap.get(reportDefinitionId));
            return;
        }
        if (!this.model.reportFormatDatasMap.has(reportDefinitionId) && this.model.reportFormatData.columnSettings.length) {
            this.model.reportFormatDatasMap.set(reportDefinitionId, this.model.reportFormatData);
            this.updateDefaultViewFromReport(this.model.reportsMap.get(reportDefinitionId));
        }
    }

    updateReportFormattingDataInSession(reportDefinitionHeader: ReportDefinitionHeader): void {
        if (this.model.reportFormatData.columnSettings.length || this.model.reportFormatData.filters.length || this.areAnyColumnsHidden() || this.areAnyColumnsLocked()) {
            this.model.reportFormatDatasMap.set(reportDefinitionHeader.id, this.model.reportFormatData);
        }
    }

    areAnyColumnsHidden(): boolean {
        return Array.from(this.model.reportFormatData.hiddenColumns.values()).some((value: boolean) => value);
    }

    areAnyColumnsLocked(): boolean {
        return Array.from(this.model.reportFormatData.lockedColumns.values()).some((value: boolean) => value);
    }

    clearSavedReportData(): void {
        if (this.model.report?.reportDefinitionId) {
            this.model.reportFormatDatasMap.set(this.model.report.reportDefinitionId, this.model.reportFormatData);
        }
        this.model.reportFormatData = new ReportFormattingData();
    }

    clearReportsModel(): void {
        this.clearSavedReportData();
        this.model.reportDefinitionsHeaders = new Array<ReportDefinitionHeader>();
    }

    clearReportsOnDestroy(): void {
        if (this.model !== undefined) {
            this.model.reportDefinitionsHeaders = new Array<ReportDefinitionHeader>();
            this.model.reportsMap = new Map<string, Report>();
        }
    }

    setApplicableConditionalLogicSetIds(project: Project, report: Report): void {
        report.selectedFields.forEach((selectedField: Field) => {
            if (selectedField.conditionalLogicIds?.length > 0) {
                this.model.applicableConditionalLogic = selectedField.conditionalLogicIds.map((conditionalLogicId: string): DynamicLogicSet => {
                    return project.conditionalLogic.find((logic: DynamicLogicSet) => logic.id === conditionalLogicId);
                });
            }
        });
    }

    isAutoSelectedField(field: Field): boolean {
        const autoSelectedFields = ['SiteNo', 'SiteName', 'SiteStatus'];
        return autoSelectedFields.includes(field.serializedId);
    }

    updateReportColumnWidth(app: App, reportDefinitionHeader: ReportDefinitionHeader, columnSettings: GridColumnProperties): void {
        this._reportDefinitionDataService.updateReportColumnWidth(app, reportDefinitionHeader, columnSettings)
            .subscribe({
                next: (result: Report) => {
                    this.model.reportsMap.set(result.reportDefinitionId, result);
                    this.updateDefaultViewFromReport(result);
                },
                error: () => {
                    this._snackBar.open('Error updating report', 'Close', { duration: 2000 });
                },
                complete: () => { },
            })
            .add(() => {
                this.model.reportLoading = false;
                this.getCurrentReportFormattingData(reportDefinitionHeader.id);
            });
    }

    updateReportViewColumnWidth(app: App, reportView: ReportView, columnChanged: GridColumnProperties): void {
        const existingIndex = reportView.columnSettings.findIndex(column => column.field === columnChanged.field);
        reportView.columnSettings[existingIndex].width = columnChanged.width;

        this._reportDefinitionDataService.updateReportView(app, reportView)
            .subscribe({
                next: (result: ReportView) => {
                    const existingReport = this.model.reportsMap.get(result.reportDefinitionId);
                    if (!existingReport.customViews) {
                        existingReport.customViews = [];
                    }

                    const existingIndex = existingReport.customViews.findIndex(view => view.id === result.id);
                    if (existingIndex !== -1)
                        existingReport.customViews[existingIndex] = result;
                }
            });
    }

    applyExistingColumnSettings(columnId: string, fieldDefaulwidth: number, columnSettings: GridColumnProperties[]): number {
        let retrievedWidth: number = null;

        columnSettings.forEach((_, index: number) => {
            if (columnSettings[index].field == columnId) {

                if (columnSettings[index].field) {
                    retrievedWidth = columnSettings[index].width;
                    return;
                }
                else {
                    columnSettings[index] = { field: columnId, width: fieldDefaulwidth };
                }
            }
        });

        if (retrievedWidth) {
            return retrievedWidth;
        }

        columnSettings[columnSettings.length] = { field: columnId, width: fieldDefaulwidth };
        return fieldDefaulwidth;
    }

    createReportView(app: App, report: Report, reportView: ReportView): void {
        this._reportDefinitionDataService.createNewReportDefinitionView(app, report, reportView)
            .subscribe({
                next: (result: ReportView) => {
                    const existingReport = this.model.reportsMap.get(result.reportDefinitionId);
                    if (!existingReport.customViews) {
                        existingReport.customViews = [];
                    }
                    existingReport.customViews.push(result);
                    this.reportViewCreated.next(result);

                    this._snackBar.open('"' + result.name + '" has been added', 'Close', { duration: 2000 });
                },
                error: () => {
                    this._snackBar.open('Error adding report view', 'Close', { duration: 2000 });
                }
            });
    }

    updateReportView(app: App, reportView: ReportView): void {
        this._reportDefinitionDataService.updateReportView(app, reportView)
            .subscribe({
                next: (result: ReportView) => {
                    const existingReport = this.model.reportsMap.get(result.reportDefinitionId);
                    const existingIndex = existingReport.customViews.findIndex((view: ReportView) => view.id === result.id);
                    if (existingIndex !== -1)
                        existingReport.customViews[existingIndex] = result;

                    this._snackBar.open('"' + result.name + '" has been updated', 'Close', { duration: 2000 });
                },
                error: () => {
                    this._snackBar.open('Error updating "' + reportView.name + '"', 'Close', { duration: 2000 });
                }
            });
    }

    deleteReportView(app: App, reportView: ReportView): void {
        this._reportDefinitionDataService.deleteReportView(app, reportView)
            .subscribe({
                next: (result: ReportView[]) => {
                    let existingReport = this.model.reportsMap.get(reportView.reportDefinitionId);

                    existingReport.customViews = result;
                    this._snackBar.open(`"${reportView.name}" has been deleted`, 'Close', { duration: 2000 });
                },
                error: () => {
                    this._snackBar.open(`Error deleting "${reportView.name}"`, 'Close', { duration: 2000 });
                }
            });
    }

    addGridColumnProperties(wrappedColumns: Map<string, boolean>, reportFormattingData: ReportFormattingData, report: Report): ReportViewGridColumnProperties[] {
        let combinedGridSettings: ReportViewGridColumnProperties[] = [];
        const widthMap = new Map<string, number>();

        reportFormattingData.columnSettings.forEach(element => {
            widthMap.set(element.field, element.width);
        });

        report.selectedFields.forEach((reportField: Field) => {

            const combinedProperties: ReportViewGridColumnProperties = {
                field: reportField.serializedId,
                width: widthMap.get(reportField.serializedId),
                locked: reportFormattingData.lockedColumns.get(reportField.serializedId),
                hidden: reportFormattingData.hiddenColumns.get(reportField.serializedId),
                shouldWrap: wrappedColumns.get(reportField.id),
                id: reportField.id
            };
            combinedGridSettings.push(combinedProperties);
        });

        return combinedGridSettings;
    }

    convertWrappedColumnsToMap(selectedView: ReportView, wrappedColumns: Map<string, boolean>): void {
        if (!selectedView || selectedView.id == this.defaultViewId) {
            if (!wrappedColumns) wrappedColumns = new Map<string, boolean>();
            wrappedColumns?.forEach((value, key) => {
                wrappedColumns.set(key, value = false);
            });
        }
        else {
            selectedView.columnSettings.forEach((column: ReportViewGridColumnProperties) => {
                wrappedColumns.set(column.id, column.shouldWrap);
            });
        }
    }

    updateDefaultViewFromReport(report: Report): void {
        this.defaultView.columnSettings = [];
        report.columnSettings?.forEach((columnProperties: GridColumnProperties) => {
            const columnPropertiesToAdd: ReportViewGridColumnProperties = {
                field: columnProperties.field,
                width: columnProperties.width,
                locked: false,
                hidden: false
            };
            this.defaultView.columnSettings.push(columnPropertiesToAdd);
        });
    }

    validateIfViewExist(report: Report, viewNameData: ReportViewMatDialogData): boolean {
        const result = report.customViews?.some((view: ReportView) => view.name.toLocaleLowerCase() === viewNameData.viewName.toLocaleLowerCase());
        if (result)
            this._snackBar.open(`A view already exist with the name: ${viewNameData.viewName}`, 'Close', { duration: 2000 });

        return result;
    }

    validateViewProjectAdmin(isProjectAdmin: boolean, adminProjects: Project[], currentProject: Project): boolean {
        if (!isProjectAdmin)
            return false;

        return adminProjects.some(project => project.id === currentProject.id);
    }
}
