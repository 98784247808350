import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PlatformAdminGuard implements CanActivate {

    constructor(
        private _router: Router,
    ) { }

    canActivate(): Observable<UrlTree | boolean> | Promise<UrlTree | boolean> | UrlTree | boolean {
        const token = sessionStorage.getItem('token');
        if (!token) this._router.navigate(["home"]);

        const decoded = jwt_decode<any>(token);
        if (!decoded.isPlatformAdmin) {
            this._router.navigate(["home"]);
        }

        return true;
    }

}
