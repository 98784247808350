<div class="kh-feedback-form-container">
    <kh-close-dialog-button (click)="closeDialog()"></kh-close-dialog-button>
    <div class="kh-feedback-form">
        <ng-template
            [ngIf]="this.model.responseLoading"
            [ngIfElse]="showForm"
        >
            <mat-spinner
                class="kh-feedback-form__spinner"
                [diameter]="150"
            ></mat-spinner>
        </ng-template>
        <ng-template #showForm>
            <div *ngIf="this.formStep === 'inProgress'">
                <div class="kh-header-container">
                    <h1>Send feedback</h1>
                    <p>*Indicates required fields</p>
                </div>
                <div class="kh-feedback-form-wrapper">
                    <form [formGroup]="feedBackForm">
                        <div>
                            <p class="kh-feedback-form__field-header">Describe your issue or suggestion*</p>
                            <textarea
                                placeholder="Tell us how we can improve our product"
                                formControlName="description"
                                class="kh-feedback-textbox"
                                required
                            ></textarea>
                        </div>
                        <div class="kh-feedback-uploader">
                            <p class="kh-feedback-form__field-header">Upload image</p>
                            <kh-file-select
                                (fileSelectedEventEmitter)="selectFile($event)"
                                (deleteFileEventEmitter)="removeFile($event)"
                                [selectedFiles]="files"
                            ></kh-file-select>
                        </div>
                        <p class="kh-feedback-form__field-header">Preferred contact method*</p>
                        <select
                            class="kh-contact-dropdown"
                            [(ngModel)]="contactMethod"
                            [ngModelOptions]="{ standalone: true }"
                            (ngModelChange)="setContactMethod($event)"
                        >
                            <option
                                class="text-secondary"
                                value=""
                                disabled
                                selected
                            >
                                Select preferred contact method...
                            </option>
                            <option
                                *ngFor="let option of contactOptions"
                                value="{{ option.name }}"
                            >
                                {{ option.name }}
                            </option>
                        </select>

                        <div *ngIf="this.contactMethod === 'Phone'">
                            <p class="kh-feedback-form__field-header">Phone number*</p>

                            <input
                                formControlName="phone"
                                type="tel"
                                value="this.feedBackForm.value.phone"
                                placeholder="xxx-xxx-xxxx"
                                class="kh-phone-input"
                            />
                        </div>
                    </form>
                    <div>
                        <p class="kh-disclaimer-text">
                            Some account and system information may be sent to Kimley-Horn Technology Solutions. We will
                            use it to fix problems and improve our services, subject to our
                            <a
                                target="_blank"
                                href="https://www.kimley-horn.com/khts-privacy-policy"
                                >Privacy-Policy</a
                            >
                            and
                            <a
                                target="_blank"
                                href="https://www.kimley-horn.com/khts-terms-of-use"
                                >Terms of Use</a
                            >. We may contact you for more information or updates using your preferred contact method.
                        </p>
                        <div class="kh-submit-footer">
                            <app-button (onClick)="submitForm()">
                                <div class="kh-send-button">
                                    <span>Send</span>
                                    <mat-icon>send</mat-icon>
                                </div>
                            </app-button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="this.formStep === 'completed'">
                <div class="kh-header-container">
                    <h1>Feedback Sent!</h1>
                </div>
                <div class="kh-feedback-form-wrapper">
                    <div>
                        <div class="kh-feedback-img">
                            <img
                                src="/../../assets/illustrations_send_feedback.svg"
                                alt=""
                            />
                        </div>
                        <ng-template
                            [ngIf]="this.confirmationNumber"
                            [ngIfElse]="showError"
                        >
                            <div>
                                <h2 class="kh-feedback-form__field-header">Thank you for your feedback!</h2>
                                <p>
                                    We use feedback like yours to help fix issues and improve the SMARTS user
                                    experience. Someone from our team will be in touch using the contact method you
                                    specified.
                                </p>
                                <p>
                                    Please feel free to reference confirmation number
                                    <span class="kh-smarts-color">{{ this.confirmationNumber }}</span
                                    >, if needed.
                                </p>
                            </div>
                        </ng-template>
                        <ng-template #showError>
                            <div>
                                <p class="kh-center-text">
                                    There has been an error processing your feedback. Please allow some time before
                                    trying again.
                                </p>
                            </div>
                        </ng-template>
                    </div>
                    <app-button
                        class="kh-close-button"
                        (onClick)="closeDialog()"
                    >
                        <div>Close</div>
                    </app-button>
                </div>
            </div>
        </ng-template>
    </div>
</div>
