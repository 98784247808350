import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { environment } from '../../../environments/environment';
import { App, Invoice } from '../../models';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class InvoiceDataService extends HttpBaseService {

    private route = environment.api + 'invoices/';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    getYearMonthInvoicesForAllApps(year: string, month: string): Observable<Invoice[]> {
        const url = `${this.route}year/${year}/month/${month}`;

        return this.httpGet(url);
    }

    getAllMonthInvoicesForYearAllApps(year: string): Observable<Invoice[]> {
        const url = `${this.route}year/${year}`;

        return this.httpGet(url);
    }

    getAllInvoicesForYear(app: App, year: string): Observable<Invoice[]> {
        const url = `${this.route}app/${app.id}/year/${year}`;

        return this.httpGet(url);
    }

    getYearMonthInvoicesByAppId(app: App, year: string, month: string): Observable<Invoice> {
        const url = `${this.route}app/${app.id}/year/${year}/month/${month}`;

        return this.httpGet(url);
    }

    processInvoices(): Observable<any> {
        const url = `${this.route}process-invoices`;
        return this.httpGet(url);
    }

    resetInvoices(): Observable<any> {
        const url = `${this.route}reset-invoices`;
        return this.httpGet(url);
    }
}
