/* istanbul ignore file */

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BulkSiteImportResultData } from '../../../../../models';
import { AppsService, HelpFeedbackServiceModel, KHThemeService } from '../../../../../services';
import { AppAliasService, HelpFeedbackLogicService } from '../../../../../services/logic-services';

@Component({
    selector: 'kh-excel-bulk-site-upload-error-dialog',
    templateUrl: './excel-bulk-site-upload-error-dialog.component.html',
    styleUrls: ['./excel-bulk-site-upload-error-dialog.component.scss']
})
export class ExcelBulkSiteUploadErrorDialogComponent {

    get model(): HelpFeedbackServiceModel { return this._helpFeedbackService.model; }
    get primaryColorFiftyOpacity(): string { return this._themeService.primaryFiftyOpacity(); }
    get primarySevenOpacity(): string { return this._themeService.primarySevenOpacity(); }
    get sitesAliasLowerCase(): string { return this._appAliasService.sitesAliasLowerCase; }
    get siteAliasLowerCase(): string { return this._appAliasService.siteAliasLowerCase; }

    get siteAlias(): string {
        return this._appsService.currentApp.aliases.site.singular;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: BulkSiteImportResultData,
        private _appAliasService: AppAliasService,
        private _appsService: AppsService,
        private _helpFeedbackService: HelpFeedbackLogicService,
        private _themeService: KHThemeService,
        public dialogRefError: MatDialogRef<ExcelBulkSiteUploadErrorDialogComponent>,
    ) { }

    openFeedbackForm(): void {
        this.model.showForm = true;
        this.dialogRefError.close(true);
    }
}
