import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SelectEvent } from '@progress/kendo-angular-upload';
import { process } from '@progress/kendo-data-query';

import { Subject } from 'rxjs';

import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { BulkSitesUploadShapefileDuplicateSitesDialogComponent } from '../../components/dialogs/bulk-site-upload-dialog/shapefile/bulk-site-upload-shapefile-duplicate-sites-dialog/bulk-site-upload-shapefile-duplicate-sites-dialog.component';
import { BulkSiteUploadShapefileSaveSitesDialogComponent } from '../../components/dialogs/bulk-site-upload-dialog/shapefile/bulk-site-upload-shapefile-save-sites-loader-dialog/bulk-site-upload-shapefile-save-sites-dialog.component';
import { App, BulkSiteImportResultData, GeoJsonFeature, Project, Site } from '../../models';
import { BulkSiteUploadShapefileActivateSitesStep, BulkSiteUploadShapefileStep, GeoJsonFeatureType, SiteTypes } from '../../shared/enums';
import { ShapefileDataService, SitesImportDataService } from '../data-services';
import { ProjectInformationDataService } from '../data-services/project-information-data.service';
import { ProjectNumberDataService } from '../data-services/project-number-data-service.service';
import { GeoJsonUtilityService } from '../utility-services';
import { ActivateSitesModel, AssociateChargeNumber, AssociateChargeNumberStep, BulkSiteUploadShapefileModel, MatchingAttributesModel, ReviewStepModel } from './models';

@Injectable({
    providedIn: 'root'
})
export class BulkSiteUploadShapefileLogicService {

    private sitesFinishedUploading = new Subject<boolean>();
    sitesFinishedUploading$ = this.sitesFinishedUploading.asObservable();

    model: BulkSiteUploadShapefileModel;

    get numSitesToActivate(): number {
        let numSitesToActivate = this.model.activateSitesStep.selectedSites.length;
        if (this.model.activateSitesStep.selectAllSite.active)
            numSitesToActivate--;
        return numSitesToActivate;
    }

    get numSitesThatBeActivated(): number { return this.model.activateSitesStep.sitesThatCanBeActivated.length - 1; }

    constructor(
        private _sitesImportDataService: SitesImportDataService,
        private _shapefileDataService: ShapefileDataService,
        private _projectInformationDataService: ProjectInformationDataService,
        private _geoJsonUtilityService: GeoJsonUtilityService,
        private _projectNumberDataService: ProjectNumberDataService,
    ) { }

    initModel(): void {
        this.model = new BulkSiteUploadShapefileModel();
    }

    isNextDisabled(currentWizardStep: number): boolean {
        switch (currentWizardStep) {
            case BulkSiteUploadShapefileStep.UploadFile:
                return !this.model.fileUploadStep.shapefile || this.model.fileUploadStep.showErrorMessage || this.model.fileUploadStep.isFetchingGeoJsonFile;
            case BulkSiteUploadShapefileStep.MatchAttributes:
                return !this.model.matchingAttributesStep.siteNoProperty || !this.model.matchingAttributesStep.siteNameProperty;
            case BulkSiteUploadShapefileStep.ActivateSites:
                if (this.model.activateSitesStep.activateSitesSubStep === BulkSiteUploadShapefileActivateSitesStep.MaxEnterpriseSites)
                    return false;

                if (this.model.activateSitesStep.activateSitesSubStep !== BulkSiteUploadShapefileActivateSitesStep.ActivateSites)
                    return this.model.activateSitesStep.sitesMissingChargeNumberAfterMatchingAttributes.every(site => { return !site.chargeNumber; });

                if (this.model.activateSitesStep.activateSites === undefined)
                    return true;

                if (!this.model.activateSitesStep.activateSites)
                    return false;

                return this.model.activateSitesStep.selectedSites.length === 0;
            default:
                return false;
        }
    }

    shapefileSelected(fileSelectEvent: SelectEvent): void {
        this.initalizeShapefileSelected();

        if (!fileSelectEvent.files[0]) {
            this.handleNoFilesSelected();
            return;
        }

        this.model.fileUploadStep.selectedShapefiles = [...fileSelectEvent.files];
        this.model.fileUploadStep.shapefile = fileSelectEvent.files[0];

        this.getGeoJsonFileFromShapefile();
    }

    initalizeShapefileSelected(): void {
        this.model.fileUploadStep.isFetchingGeoJsonFile = true;
        this.model.fileUploadStep.showErrorMessage = false;

        this.model.matchingAttributesStep = new MatchingAttributesModel();
        this.model.activateSitesStep = new ActivateSitesModel();
        this.model.reviewStep = new ReviewStepModel();
    }

    handleNoFilesSelected(): void {
        this.model.fileUploadStep.selectedShapefiles = [];
        this.model.fileUploadStep.shapefile = undefined;
        this.model.fileUploadStep.showErrorMessage = true;
        this.model.fileUploadStep.isFetchingGeoJsonFile = false;
    }

    shapefileRemoved(): void {
        this.model.fileUploadStep.selectedShapefiles = [];
        this.model.fileUploadStep.shapefile = undefined;
        this.model.geoJsonFile = undefined;
        this.model.fileUploadStep.showErrorMessage = false;
    }

    getGeoJsonFileFromShapefile(): void {
        const formData = new FormData();
        formData.append('file', this.model.fileUploadStep.shapefile.rawFile);

        this._shapefileDataService.getSitesGeoJsonFile(formData)
            .subscribe({
                next: (base64File: string) => {
                    try {
                        this.model.geoJsonFile = JSON.parse(atob(base64File));
                        this.getPotentialPropertiesFromGeojson();
                    }
                    catch (error) {
                        this.model.fileUploadStep.showErrorMessage = true;
                    }
                },
                error: () => {
                    this.model.fileUploadStep.showErrorMessage = true;
                },
                complete: () => {
                    this.model.fileUploadStep.isFetchingGeoJsonFile = false;
                },
            });
    }

    getPotentialPropertiesFromGeojson(): void {
        if (!this.model.geoJsonFile)
            return;

        const featureCount = this.model.geoJsonFile.features.length;
        if (featureCount === 0) {
            this.model.matchingAttributesStep.properties = [];
            this.model.matchingAttributesStep.commonProperties = [];
            this.model.matchingAttributesStep.commonPropertiesUniqueValues = [];
            this.model.matchingAttributesStep.showErrorMessage = true;
            return;
        }

        this.populatePotentialProperties();
    }

    populatePotentialProperties(): void {
        const allProperties = new Set<string>();
        let commonProperties = new Set<string>();
        let currentFeatureProps: Set<string>;

        this.model.geoJsonFile.features.forEach(feature => {
            const properties = feature.properties;
            currentFeatureProps = new Set<string>();

            Object.keys(properties).forEach(key => {
                const value = properties[key]?.toString().trim();
                if (!value)
                    return;

                if (!this.model.matchingAttributesStep.keysUniqueValue.has(key))
                    this.model.matchingAttributesStep.keysUniqueValue.set(key, new Set());

                this.model.matchingAttributesStep.keysUniqueValue.get(key).add(value);

                allProperties.add(key);
                currentFeatureProps.add(key);
            });

            if (commonProperties.size === 0) {
                commonProperties = currentFeatureProps;
                return;
            }

            commonProperties.forEach(prop => {
                if (!currentFeatureProps.has(prop))
                    commonProperties.delete(prop);
            });
        });

        this.model.matchingAttributesStep.properties = this.sortProperties(Array.from(allProperties));
        this.model.matchingAttributesStep.commonProperties = this.sortProperties(Array.from(commonProperties));
        this.model.matchingAttributesStep.commonPropertiesUniqueValues = this.sortProperties(Array.from(this.model.matchingAttributesStep.keysUniqueValue.entries())
            .filter(([, value]) => value.size === this.model.geoJsonFile.features.length)
            .map(([key,]) => key));
    }

    sortProperties(array: string[]): string[] {
        return array.sort((a, b) => {
            const nameA = a.toUpperCase();
            const nameB = b.toUpperCase();
            if (nameA < nameB)
                return -1;

            if (nameA > nameB)
                return 1;

            return 0;
        });
    }

    setFirstSiteNoValue(): void {
        if (!this.model.matchingAttributesStep.siteNoProperty) {
            this.model.matchingAttributesStep.firstSiteNoValue = undefined;
            return;
        }

        this.model.matchingAttributesStep.firstSiteNoValue = this.getFirstValueForProperty(this.model.matchingAttributesStep.siteNoProperty);
    }

    setFirstSiteNameValue(): void {
        if (!this.model.matchingAttributesStep.siteNameProperty) {
            this.model.matchingAttributesStep.firstSiteNameValue = undefined;
            return;
        }

        this.model.matchingAttributesStep.firstSiteNameValue = this.getFirstValueForProperty((this.model.matchingAttributesStep.siteNameProperty));
    }

    setFirstChargeNoValue(): void {
        if (!this.model.matchingAttributesStep.siteChargeNoProperty) {
            this.model.matchingAttributesStep.firstSiteChargeNoValue = undefined;
            return;
        }

        this.model.matchingAttributesStep.firstSiteChargeNoValue = this.getFirstValueForProperty(this.model.matchingAttributesStep.siteChargeNoProperty);
    }

    getFirstValueForProperty(property: string): string {
        if (!this.model.geoJsonFile)
            return undefined;

        const uniqueValuesForProperty = this.model.matchingAttributesStep.keysUniqueValue.get(property);
        for (let uniqueValue of uniqueValuesForProperty) {
            if (uniqueValue && uniqueValue.trim() !== '')
                return uniqueValue;
        }

        return undefined;
    }

    getChargeNumberValidities(chargeNumbersWithValidFormat: string[]): void {
        this.model.chargeNumberValidities = {};
        if (!chargeNumbersWithValidFormat.length)
            return;

        this.model.activateSitesStep.isFetchingChargeNumberValiditites = true;
        this._projectInformationDataService.getChargeNumberValidities(chargeNumbersWithValidFormat)
            .subscribe({
                next: (chargeNumberValidities: { [key: string]: boolean; }) => {
                    this.model.chargeNumberValidities = chargeNumberValidities ?? {};
                    this.setInitialSitesToFix();
                },
                error: () => {
                    this.model.activateSitesStep.showErrorMessage = true;
                },
                complete: () => {
                    this.model.activateSitesStep.isFetchingChargeNumberValiditites = false;
                },
            });
    }

    checkChargeNumberValitiyForAssociateMapping(associateChargeNumber: AssociateChargeNumber): void {
        const isChargeNumberValid = this.model.chargeNumberValidities[associateChargeNumber.chargeNumber];
        if (isChargeNumberValid) {
            associateChargeNumber.chargeNumberValid = true;
            return;
        }

        if (isChargeNumberValid !== undefined)
            return;

        this.updateChargeNumberValitiyForAssociateMapping(associateChargeNumber);
    }

    updateChargeNumberValitiyForAssociateMapping(associateChargeNumber: AssociateChargeNumber): void {
        associateChargeNumber.chargeNumberValidating = true;
        this._projectNumberDataService.validateChargeNo(associateChargeNumber.chargeNumber).subscribe({
            next: (isValidChargeNo) => {
                associateChargeNumber.chargeNumberValid = isValidChargeNo['exists'];
                associateChargeNumber.chargeNumberValidating = false;
                this.model.chargeNumberValidities[associateChargeNumber.chargeNumber] = associateChargeNumber.chargeNumberValid;
                if (!associateChargeNumber.isEditingChargeNumber)
                    this.resetAssociateChargeNumberSitesIfChargeNumberIsInvalid(associateChargeNumber);
            },
            error: (error) => {
                throw new Error(error);
            }
        });
    }

    setActivateSitesSubStep(isAppLicenseEnterprise: boolean, currentAppActiveSitesCount: number, currentAppMaxSitesAllowed: number): void {
        if (isAppLicenseEnterprise) {
            this.model.activateSitesStep.isAssociateChargeNumbersStepApartOfTheUploadFlow = false;
            if (currentAppActiveSitesCount >= currentAppMaxSitesAllowed) {
                this.model.activateSitesStep.activateSitesSubStep = BulkSiteUploadShapefileActivateSitesStep.MaxEnterpriseSites;
                return;
            }

            this.model.activateSitesStep.activateSitesSubStep = BulkSiteUploadShapefileActivateSitesStep.ActivateSites;
            return;
        }

        if (!this.model.matchingAttributesStep.siteChargeNoProperty) {
            this.model.activateSitesStep.isAssociateChargeNumbersStepApartOfTheUploadFlow = true;
            this.model.activateSitesStep.activateSitesSubStep = BulkSiteUploadShapefileActivateSitesStep.AssociateChargeNumber;
            return;
        }

        if (this.model.activateSitesStep.sitesMissingChargeNumberAfterMatchingAttributes.length) {
            this.model.activateSitesStep.isAssociateChargeNumbersStepApartOfTheUploadFlow = true;
            this.model.activateSitesStep.activateSitesSubStep = BulkSiteUploadShapefileActivateSitesStep.AssociateChargeNumber;
            return;
        }

        this.model.activateSitesStep.isAssociateChargeNumbersStepApartOfTheUploadFlow = false;
        this.model.activateSitesStep.activateSitesSubStep = BulkSiteUploadShapefileActivateSitesStep.ActivateSites;
    }

    convertGeoJsonFeaturesToSites(currentProject: Project): void {
        this.model.sites = [];
        if (!this.model.geoJsonFile?.features.length)
            return;

        this.model.activateSitesStep.sitesMissingChargeNumberAfterMatchingAttributes = [];
        this.model.geoJsonFile.features.forEach(feature => {
            const site = new Site();
            this.setSiteProperties(currentProject, site, feature);
            this.model.sites.push(site);
            if (!site.chargeNumber)
                this.model.activateSitesStep.sitesMissingChargeNumberAfterMatchingAttributes.push(site);
        });
    }

    setSiteProperties(currentProject: Project, site: Site, feature: GeoJsonFeature): void {
        site.siteNo = feature.properties[this.model.matchingAttributesStep.siteNoProperty]?.toString();
        site.siteName = feature.properties[this.model.matchingAttributesStep.siteNameProperty]?.toString();
        site.chargeNumber = feature.properties[this.model.matchingAttributesStep.siteChargeNoProperty]?.toString();
        site.siteType = feature.geometry.type !== GeoJsonFeatureType.Point ? SiteTypes.LineArea : SiteTypes.Point;
        site.geoJson = feature;
        site.projects = [currentProject.id];
        this._geoJsonUtilityService.setSiteCenterPoint(site);

        const dropdownName = `${site.siteNo} - ${site.siteName}`;
        site.dropdownName = dropdownName.length < 40 ? dropdownName : dropdownName.substring(0, 40).concat('...');
    }

    handleInitStepDataOnStepSwitch(currentWizardStep: number, currentProject: Project, isAppLicenseEnterprise: boolean, currentAppActiveSitesCount: number, currentAppMaxSitesAllowed: number): void {
        switch (currentWizardStep) {
            case BulkSiteUploadShapefileStep.ActivateSites:
                this.handleSwitchToActivateSitesStep(currentProject, isAppLicenseEnterprise, currentAppActiveSitesCount, currentAppMaxSitesAllowed);
                break;
            case BulkSiteUploadShapefileStep.Review:
                this.handleSwitchToReviewStep();
                break;
        }
    }

    handleSwitchToActivateSitesStep(currentProject: Project, isAppLicenseEnterprise: boolean, currentAppActiveSitesCount: number, currentAppMaxSitesAllowed: number): void {
        this.convertGeoJsonFeaturesToSites(currentProject);
        this.resetActivateSitesStep();
        this.model.activateSitesStep.modeledSites = [];

        this.setActivateSitesSubStep(isAppLicenseEnterprise, currentAppActiveSitesCount, currentAppMaxSitesAllowed);
        if (this.model.activateSitesStep.activateSitesSubStep === BulkSiteUploadShapefileActivateSitesStep.AssociateChargeNumber) {
            this.handleSwitchToAssociateChargeNumberStep();
            return;
        }

        this.handleSwitchToActivateSitesSubStep(isAppLicenseEnterprise);
    }

    handleSkipAssociateChargeNumbersAndGoToActivateStep(): void {
        this.handleSkipAssociateChargeNumbers();
        this.setInitialSitesToFix();
    }

    handleSkipAssociateChargeNumbers(): void {
        this.model.activateSitesStep.associateChargeNumberSubStep.associateChargeNumbers = [];
        this.addAssociateChargeNumberMapping();
        this.model.activateSitesStep.associateChargeNumberSubStep.sitesStillMissingChargeNumbers =
            [...this.model.activateSitesStep.sitesMissingChargeNumberAfterMatchingAttributes];
        this.model.activateSitesStep.sitesMissingChargeNumberAfterMatchingAttributes.forEach(site => {
            site.chargeNumber = undefined;
            site.isChargeNumberValid = false;
        });
        this.resetActivateSitesStep();
    }

    handleSwitchToActivateSitesSubStep(isAppLicenseEnterprise: boolean): void {
        this.resetActivateSitesStep();
        this.setSitesThatCanBeActivated(isAppLicenseEnterprise);
        this.setFilteredSitesThatCanBeActivated();
        this.setInitialSitesToFix();
    }

    resetActivateSitesStep(): void {
        this.model.activateSitesStep.activateSites = undefined;
        this.model.activateSitesStep.selectAllSite.active = false;
        this.model.activateSitesStep.modeledSites = [];
        this.model.activateSitesStep.selectedSites = [];
        this.model.activateSitesStep.showCustomPlaceholder = true;
        this.model.sites.forEach(site => {
            site.active = false;
        });
    }

    setSitesThatCanBeActivated(isAppLicenseEnterprise: boolean): void {
        this.setControlSite(this.model.activateSitesStep.selectAllSite);
        if (isAppLicenseEnterprise) {
            this.model.activateSitesStep.sitesThatCanBeActivated = [this.model.activateSitesStep.selectAllSite, ...this.model.sites];
            return;
        }

        this.model.activateSitesStep.sitesThatCanBeActivated = [this.model.activateSitesStep.selectAllSite, ...this.model.sites.filter(site => site.chargeNumber || site.siteType === SiteTypes.Control)];
    }

    setControlSite(site: Site): void {
        site.chargeNumber = undefined;
        site.siteType = SiteTypes.Control;
        site.siteNo = this.model.activateSitesStep.selectAllSiteNo;
        site.dropdownName = this.model.activateSitesStep.selectAllMessage;
    }

    setFilteredSitesThatCanBeActivated(): void {
        this.model.activateSitesStep.filteredSitesThatCanBeActivated = [...this.model.activateSitesStep.sitesThatCanBeActivated];
    }

    filterActivateSites(filter: string): void {
        if (!filter) {
            this.model.activateSitesStep.filteredSitesThatCanBeActivated = [...this.model.activateSitesStep.sitesThatCanBeActivated];
            return;
        }

        this.model.activateSitesStep.filteredSitesThatCanBeActivated = this.model.activateSitesStep.sitesThatCanBeActivated.filter(site => {
            return site.dropdownName.toLowerCase().includes(filter.toLowerCase()) && site.siteType !== SiteTypes.Control;
        });
    }

    handleActivateSiteChanged(isAppLicenseEnterprise: boolean, numberOfSitesLeftOnLicenseTier: number): void {
        const siteToActivate: Site = this.model.activateSitesStep.modeledSites.find(site => {
            return !this.model.activateSitesStep.selectedSites.includes(site);
        });
        this.activateSiteEvent(siteToActivate, isAppLicenseEnterprise, numberOfSitesLeftOnLicenseTier);
    }

    activateSiteEvent(siteToActivate: Site, isAppLicenseEnterprise: boolean, numberOfSitesLeftOnLicenseTier: number): void {
        if (isAppLicenseEnterprise && this.isAppAtSiteLimit(numberOfSitesLeftOnLicenseTier)) {
            this.model.activateSitesStep.modeledSites = [...this.model.activateSitesStep.selectedSites];
            return;
        }

        if (siteToActivate.siteType === SiteTypes.Control) {
            this.activateAllSites(isAppLicenseEnterprise, numberOfSitesLeftOnLicenseTier);
            return;
        }

        this.activateSite(siteToActivate);
    }

    activateSite(site: Site): void {
        site.active = true;
        this.model.activateSitesStep.selectedSites.push(site);

        this.activateTheSelectAllSiteIfAllSitesHaveBeenActivated();
    }

    activateTheSelectAllSiteIfAllSitesHaveBeenActivated(): void {
        if (this.numSitesToActivate !== this.numSitesThatBeActivated)
            return;

        this.model.activateSitesStep.selectAllSite.active = true;
        this.model.activateSitesStep.selectedSites.push(this.model.activateSitesStep.selectAllSite);
        this.model.activateSitesStep.modeledSites = [...this.model.activateSitesStep.selectedSites];
    }

    activateAllSites(isAppLicenseEnterprise: boolean, numberOfSitesLeftOnLicenseTier: number): void {
        for (const site of this.model.activateSitesStep.sitesThatCanBeActivated) {
            if (isAppLicenseEnterprise && this.isAppAtSiteLimit(numberOfSitesLeftOnLicenseTier)) {
                this.model.activateSitesStep.modeledSites = [...this.model.activateSitesStep.selectedSites];
                return;
            }

            if (site.active || site.siteType === SiteTypes.Control)
                continue;

            site.active = true;
            this.model.activateSitesStep.selectedSites.push(site);
        }

        this.model.activateSitesStep.selectAllSite.active = true;
        this.model.activateSitesStep.selectedSites.push(this.model.activateSitesStep.selectAllSite);
        this.model.activateSitesStep.modeledSites = [...this.model.activateSitesStep.selectedSites];
    }

    isAppAtSiteLimit(numberOfSitesLeftOnLicenseTier: number): boolean {
        return this.numSitesToActivate >= numberOfSitesLeftOnLicenseTier;
    }

    deactivateSiteChanged(): void {
        const siteToDeactivate: Site = this.model.activateSitesStep.selectedSites.find(site => {
            return !this.model.activateSitesStep.modeledSites.includes(site);
        });

        this.deactivateSiteEvent(siteToDeactivate);
    }

    deactivateSiteEvent(siteToDeactivate: Site): void {
        if (siteToDeactivate.siteType === SiteTypes.Control) {
            this.model.activateSitesStep.sitesThatCanBeActivated.forEach(site => site.active = false);
            this.model.activateSitesStep.selectedSites = [];
            this.model.activateSitesStep.modeledSites = [];
            return;
        }

        siteToDeactivate.active = false;

        this.model.activateSitesStep.selectedSites = this.model.activateSitesStep.selectedSites.filter(site => {
            return site !== siteToDeactivate &&
                site !== this.model.activateSitesStep.selectAllSite;
        });

        if (!this.model.activateSitesStep.selectAllSite.active)
            return;

        this.model.activateSitesStep.selectAllSite.active = false;
        this.model.activateSitesStep.modeledSites = [...this.model.activateSitesStep.selectedSites];
    }

    handleSwitchToAssociateChargeNumberStep(): void {
        this.model.activateSitesStep.associateChargeNumberSubStep = new AssociateChargeNumberStep();
        this.model.activateSitesStep.associateChargeNumberSubStep.sitesStillMissingChargeNumbers =
            [...this.model.activateSitesStep.sitesMissingChargeNumberAfterMatchingAttributes];
        this.addAssociateChargeNumberMapping();
    }

    addAssociateChargeNumberMapping(): void {
        const associateChargeNumber: AssociateChargeNumber = new AssociateChargeNumber();
        this.setControlSite(associateChargeNumber.selectAllSite);
        this.model.activateSitesStep.associateChargeNumberSubStep.associateChargeNumbers.push(associateChargeNumber);
    }

    setPotentialChargeNumbers(associateChargeNumber: AssociateChargeNumber): void {
        const sitesStillMissingChargeNumbers: Site[] = this.model.activateSitesStep.associateChargeNumberSubStep.sitesStillMissingChargeNumbers;
        const selectedSites: Site[] = associateChargeNumber.selectedSites.filter(site => {
            return site.siteType !== SiteTypes.Control;
        });

        associateChargeNumber.potentialSites = [associateChargeNumber.selectAllSite, ...selectedSites, ...sitesStillMissingChargeNumbers];
        associateChargeNumber.filteredSites = [...associateChargeNumber.potentialSites];

        if (selectedSites.length === associateChargeNumber.potentialSites.length - 1) {
            associateChargeNumber.selectAllSite.chargeNumber = associateChargeNumber.chargeNumber;
            associateChargeNumber.selectedSites = [...associateChargeNumber.potentialSites];
            associateChargeNumber.modeledSites = [...associateChargeNumber.potentialSites];
            return;
        }

        associateChargeNumber.selectAllSite.chargeNumber = undefined;
        associateChargeNumber.selectedSites = [...selectedSites];
        associateChargeNumber.modeledSites = [...selectedSites];
    }

    removedSelectedSitesFromSitesMissingChargeNumbers(associateChargeNumber: AssociateChargeNumber): void {
        const potentialSites: Site[] = associateChargeNumber.potentialSites.filter(site => {
            return site.siteType !== SiteTypes.Control;
        });

        this.model.activateSitesStep.associateChargeNumberSubStep.sitesStillMissingChargeNumbers =
            potentialSites.filter(site => {
                return site.chargeNumber === undefined &&
                    site.siteType !== SiteTypes.Control;
            });
    }

    handleAssociateSiteWithChargeNumber(associateChargeNumber: AssociateChargeNumber): void {
        const siteToAssociate: Site = associateChargeNumber.modeledSites.find(site => {
            return !associateChargeNumber.selectedSites.includes(site);
        });

        if (siteToAssociate.siteType === SiteTypes.Control) {
            this.associateAllSitesWithChargeNumber(associateChargeNumber);
            return;
        }

        this.associateSiteWithChargeNumber(siteToAssociate, associateChargeNumber);
    }

    associateAllSitesWithChargeNumber(associateChargeNumber: AssociateChargeNumber): void {
        for (const site of associateChargeNumber.potentialSites) {
            if (site.chargeNumber === associateChargeNumber.chargeNumber)
                continue;

            site.chargeNumber = associateChargeNumber.chargeNumber;
            associateChargeNumber.selectedSites.push(site);
        }

        associateChargeNumber.modeledSites = [...associateChargeNumber.selectedSites];
    }

    associateSiteWithChargeNumber(site: Site, associateChargeNumber: AssociateChargeNumber): void {
        site.chargeNumber = associateChargeNumber.chargeNumber;
        associateChargeNumber.selectedSites.push(site);

        this.associateTheSelectAllSiteIfAllSitesHaveBeenAssociated(associateChargeNumber);
    }

    associateTheSelectAllSiteIfAllSitesHaveBeenAssociated(associateChargeNumber: AssociateChargeNumber): void {
        if (associateChargeNumber.selectedSites.length !== associateChargeNumber.potentialSites.length - 1)
            return;

        associateChargeNumber.selectedSites.push(associateChargeNumber.selectAllSite);
        associateChargeNumber.modeledSites = [...associateChargeNumber.selectedSites];
        associateChargeNumber.selectAllSite.chargeNumber = associateChargeNumber.chargeNumber;
    }

    disassociateSiteFromChargeNumber(associateChargeNumber: AssociateChargeNumber): void {
        const siteToDisassociate: Site = associateChargeNumber.selectedSites.find(site => {
            return !associateChargeNumber.modeledSites.includes(site);
        });

        if (siteToDisassociate.siteType === SiteTypes.Control) {
            associateChargeNumber.potentialSites.forEach(site => site.chargeNumber = undefined);
            associateChargeNumber.selectedSites = [];
            associateChargeNumber.modeledSites = [];
            return;
        }

        siteToDisassociate.chargeNumber = undefined;
        associateChargeNumber.selectAllSite.chargeNumber = undefined;
        associateChargeNumber.selectedSites = associateChargeNumber.selectedSites.filter(site => {
            return site !== siteToDisassociate &&
                site !== associateChargeNumber.selectAllSite;
        });

        associateChargeNumber.modeledSites = [...associateChargeNumber.selectedSites];
    }


    filterAssociateSites(associateChargeNumber: AssociateChargeNumber, filter: string): void {
        if (!filter) {
            associateChargeNumber.filteredSites = [...associateChargeNumber.potentialSites];
            return;
        }

        associateChargeNumber.filteredSites = associateChargeNumber.potentialSites.filter(site => {
            return site.dropdownName.toLowerCase().includes(filter.toLowerCase()) && site.siteType !== SiteTypes.Control;
        });
    }

    associateChargeNumberChanged(associateChargeNumber: AssociateChargeNumber): void {
        associateChargeNumber.selectedSites.forEach(site => site.chargeNumber = associateChargeNumber.chargeNumber);
    }

    showAssociatChargeNumberError(associateChargeNumber: AssociateChargeNumber): boolean {
        return associateChargeNumber.chargeNumber && !associateChargeNumber.chargeNumberValid && !associateChargeNumber.chargeNumberValidating;
    }

    associateSitesDropdownEnabled(associateChargeNumber: AssociateChargeNumber): boolean {
        return associateChargeNumber.chargeNumber && associateChargeNumber.chargeNumberValid;
    }

    resetAssociateChargeNumberSitesIfChargeNumberIsInvalid(associateChargeNumber: AssociateChargeNumber): void {
        if (associateChargeNumber.chargeNumberValid ||
            !associateChargeNumber.selectedSites.length ||
            associateChargeNumber.chargeNumberValidating
        )
            return;

        associateChargeNumber.selectedSites.forEach(site => {
            site.active = false;
            site.chargeNumber = undefined;
            site.isChargeNumberValid = false;
        });

        associateChargeNumber.modeledSites = [];
        associateChargeNumber.selectedSites = [];
        associateChargeNumber.filteredSites = [];
        this.removedSelectedSitesFromSitesMissingChargeNumbers(associateChargeNumber);
    }

    showSkipButton(currentWizardStep: number): boolean {
        return currentWizardStep === BulkSiteUploadShapefileStep.ActivateSites;
    }

    handleSwitchToReviewStep(): void {
        if (!this.model.activateSitesStep.activateSites)
            this.resetActivateSitesStep();

        this.model.reviewStep.siteNoBeingEdited = undefined;
        this.model.reviewStep.isEditing = false;
        this.setReviewDataSource();
    }

    setInitialSitesToFix(): void {
        this.model.reviewStep.intialSitesToFix = [];
        this.model.sites.forEach(site => {
            site.isChargeNumberValid = this.model.chargeNumberValidities[site.chargeNumber];
            if (!site.isChargeNumberValid)
                this.model.reviewStep.intialSitesToFix.push(site);
        });
    }

    setReviewDataSource(): void {
        this.model.reviewStep.sitesLeftToFix = this.model.reviewStep.intialSitesToFix.filter(site => !site.isChargeNumberValid);
        this.model.reviewStep.dataSource = process(this.model.sites, this.model.reviewStep.state);
    }

    reviewStepShowValidCheckMark(site: Site): boolean {
        if (!this.model.reviewStep.intialSitesToFix.includes(site))
            return false;

        if (!site.chargeNumber || !site.isChargeNumberValid)
            return false;

        return true;
    }


    reviewStepEditChargeNumberClicked(site: Site): void {
        this.resetCurrentSiteBeingEdited();
        this.model.reviewStep.isEditing = true;
        this.model.reviewStep.editingChargeNumberOriginalValue = site.chargeNumber;
        this.model.reviewStep.siteNoBeingEdited = site.siteNo;
    }

    resetCurrentSiteBeingEdited(): void {
        if (!this.model.reviewStep.isEditing)
            return;
        const site = this.model.sites.find(site => site.siteNo === this.model.reviewStep.siteNoBeingEdited);
        if (!site)
            return;
        this.cancelReviewStepEditChargeNumber(site);
    }

    showChargeNumberActivateCheck(site: Site, isAppLicensePerSite: boolean): boolean {
        if (!site.active)
            return false;

        if (!isAppLicensePerSite)
            return true;

        return site.isChargeNumberValid;
    }

    saveReviewStepEditChargeNumber(site: Site): void {
        this.model.reviewStep.isEditing = false;
        this.model.reviewStep.siteNoBeingEdited = undefined;
        this.model.reviewStep.editingChargeNumberOriginalValue = '';
        this.checkChargeNumberValitiyForReview(site);
        this.model.reviewStep.sitesLeftToFix = this.model.reviewStep.sitesLeftToFix.filter(invalidSite => invalidSite !== site);
    }

    cancelReviewStepEditChargeNumber(site: Site): void {
        site.chargeNumber = this.model.reviewStep.editingChargeNumberOriginalValue;
        this.model.reviewStep.isEditing = false;
        this.model.reviewStep.siteNoBeingEdited = undefined;
        this.model.reviewStep.editingChargeNumberOriginalValue = '';
        this.checkChargeNumberValitiyForReview(site);
    }

    checkChargeNumberValitiyForReview(site: Site): void {
        const isChargeNumberValid = this.model.chargeNumberValidities[site.chargeNumber];
        if (isChargeNumberValid !== undefined) {
            site.isChargeNumberValid = isChargeNumberValid;
            return;
        }

        this.updateChargeNumberValitiyForReview(site);
    }

    updateChargeNumberValitiyForReview(site: Site): void {
        this._projectNumberDataService.validateChargeNo(site.chargeNumber).subscribe({
            next: (isValidChargeNo) => {
                site.isChargeNumberValid = isValidChargeNo['exists'];
                this.model.chargeNumberValidities[site.chargeNumber] = site.isChargeNumberValid;
                if (!site.active)
                    this.model.reviewStep.sitesLeftToFix = this.model.reviewStep.sitesLeftToFix.filter(invalidSite => invalidSite !== site);
            },
            error: (error) => {
                throw new Error(error);
            }
        });
    }

    deactivateInvalidChargeNumberSites(): void {
        this.model.activateSitesStep.selectedSites.forEach(site => {
            if (site.active && !site.isChargeNumberValid)
                site.active = false;
        });
    }

    reviewStepDataStateChange(event: DataStateChangeEvent): void {
        this.model.reviewStep.state = event;
        this.model.reviewStep.dataSource = process(this.model.sites, this.model.reviewStep.state);
    }

    submitForm(app: App, project: Project, existingSites: Site[], dialog: MatDialog): void {
        this.checkForSitesToBeOverridden(existingSites);
        if (this.model.overrideSitesStep.overriddenSites.length) {
            this.openDuplicateSitesDialog(app, project, dialog);
            return;
        }
        this.prepareSaveSites(app, project, dialog);
    }

    checkForSitesToBeOverridden(existingSites: Site[]): void {
        this.model.overrideSitesStep.overriddenSites = this.model.sites.filter(site => {
            if (site.siteType === SiteTypes.Control)
                return false;
            return existingSites.some(currentSite => currentSite.siteNo === site.siteNo);
        });
    }

    openDuplicateSitesDialog(app: App, project: Project, dialog: MatDialog,): void {
        this.model.overrideSitesStep.dataSource = process(this.model.overrideSitesStep.overriddenSites, this.model.overrideSitesStep.state);
        const duplicateDialog = dialog.open(BulkSitesUploadShapefileDuplicateSitesDialogComponent, {
            width: "43.875rem",
            disableClose: true,
        });

        duplicateDialog.afterClosed().subscribe(dialogResult => {
            if (!dialogResult)
                return;
            this.prepareSaveSites(app, project, dialog);
        });
    }

    prepareSaveSites(app: App, project: Project, dialog: MatDialog): void {
        this.model.saveSitesStep.currentApp = app;
        this.model.saveSitesStep.currentProject = project;
        this.model.saveSitesStep.totalSitesToSend = this.model.sites.length;
        this.model.saveSitesStep.sitesToSend = [...this.model.sites];

        this.openSaveSitesDialog(dialog);
        this.saveSites();
    }

    openSaveSitesDialog(dialog: MatDialog): void {
        this.model.saveSitesStep.saveSitesDialogRef = dialog.open(BulkSiteUploadShapefileSaveSitesDialogComponent, {
            width: "35rem",
            disableClose: true,
        });
    }

    saveSites(): void {
        this.model.saveSitesStep.sentSites = this.model.saveSitesStep.sentSites.concat(this.model.saveSitesStep.sitesBeingSent);
        this.model.saveSitesStep.sitesBeingSent = this.model.saveSitesStep.sitesToSend.splice(0, this.model.saveSitesStep.numberOfSitesToSaveAtOnce);
        this.model.saveSitesStep.percentComplete = Math.floor((this.model.saveSitesStep.sentSites.length / this.model.saveSitesStep.totalSitesToSend) * 100);

        this.model.saveSitesStep.areSitesBeingSaved = true;
        this._sitesImportDataService.importSitesFromShapefile(this.model.saveSitesStep.currentApp, this.model.saveSitesStep.currentProject, this.model.saveSitesStep.sitesBeingSent)
            .subscribe(() => { });
        this.startTimerForSavingSites();
    }

    startTimerForSavingSites(): void {
        let intervalId: NodeJS.Timeout;

        intervalId = setInterval(() => {
            if (!this.model.saveSitesStep.areSitesBeingSaved) {
                clearInterval(intervalId);
                this.startTimerForSavingSites();
                return;
            }
            this._sitesImportDataService.checkBulkSitesProcessing(this.model.saveSitesStep.currentApp.id)
                .subscribe({
                    next: (isStillProcessing: BulkSiteImportResultData) => {
                        clearInterval(intervalId);
                        this.model.saveSitesStep.areSitesBeingSaved = isStillProcessing.bulkSitesProcessing;
                        if (this.model.saveSitesStep.areSitesBeingSaved) {
                            this.startTimerForSavingSites();
                            return;
                        }
                        if (this.model.saveSitesStep.sitesToSend.length === 0) {
                            this.finishUploadingSites();
                            return;
                        }
                        this.saveSites();
                    },
                    error: () => {
                        clearInterval(intervalId);
                    }
                });
        }, this.model.saveSitesStep.msBetweenCheckingSaves);
    }

    finishUploadingSites(): void {
        this.model.saveSitesStep.percentComplete = 100;
        this.model.saveSitesStep.saveSitesDialogRef.close();
        this.sitesFinishedUploading.next(true);
    }
}
