import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';

import { KHFileInfo } from '../../../../models';
import { FileLogicService } from '../../../../services/logic-services';

@Component({
    selector: 'kh-file-select',
    templateUrl: './kh-file-select.component.html',
    styleUrls: ['./kh-file-select.component.scss']
})
export class KHFileSelectComponent {

    @Output() fileSelectedEventEmitter = new EventEmitter<SelectEvent>();
    @Output() deleteFileEventEmitter = new EventEmitter<KHFileInfo>();
    @Output() previewFileEventEmitter = new EventEmitter<KHFileInfo>();
    @Output() downloadFileEventEmitter = new EventEmitter<KHFileInfo>();

    @Input() selectedFiles: KHFileInfo[] = [];

    @Input() uploadRestrictions: FileRestrictions;
    @Input() acceptedFileTypes: string[];

    @Input() isEditable = true;
    @Input() isDownloadable = true;
    @Input() isPreviewable = true;

    @Input() isLoading = false;

    @Input() hasError = false;
    @Input() errorMessage: string;
    @Input() guideMessage: string;

    constructor(
        private _fileLogicService: FileLogicService
    ) { }

    fileSelected(fileSelectEvent: SelectEvent): void {
        this.fileSelectedEventEmitter.emit(fileSelectEvent);
    }

    deleteFile(selectedFile: KHFileInfo): void {
        this.deleteFileEventEmitter.emit(selectedFile);
    }

    previewFile(selectedFile: KHFileInfo): void {
        this.previewFileEventEmitter.emit(selectedFile);
    }

    downloadFile(selectedFile: KHFileInfo): void {
        this.downloadFileEventEmitter.emit(selectedFile);
    }

    getFileSize(fileSize: number): string {
        return this._fileLogicService.niceFormatBytes(fileSize);
    }

    getFileIconFromFileInfo(file: KHFileInfo): string {
        return this._fileLogicService.getFileIconFromFileInfo(file);
    }

    showGuideMessage(): boolean {
        return this.guideMessage && !this.selectedFiles.length && !this.hasError;
    }

    showErrorMessage(): boolean {
        return this.errorMessage && this.hasError;
    }
}
