<div
    class="kh-form-header-text"
    mat-dialog-title
>
    Bulk Upload {{ siteAlias }}
</div>

<div
    mat-dialog-content
    class="kh-dialog-content"
>
    <div class="kh-text">
        <span class="kh-bold-text">{{ percentComplete }}%&nbsp;</span>
        <span>complete</span>
    </div>
    <mat-progress-bar
        class="khts-mat-progress-bar"
        mode="determinate"
        [value]="percentComplete">
    </mat-progress-bar>
</div>

<div
    mat-dialog-actions
    class="kh-dialog-controls-container"
>
    <div class="kh-dialog-controls">
        <div class="kh-alert">
            <div class="kh-text kh-bold-text">
                Please keep this window open while we upload your {{ sitesAliasLowerCase }}.
            </div>
            <div class="kh-text">
                This may take a few minutes.
            </div>
        </div>
    </div>
</div>
