import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { App, Project, ReportDefinition, ReportView } from '../../models';
import { GridColumnProperties, Report, ReportDefinitionHeader } from '../logic-services/models';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
/* istanbul ignore file */
export class ReportsDefinitionDataService extends HttpBaseService {

    route = environment.api + 'ReportDefinitions/app/';

    constructor(private _httpClient: HttpClient) {
        super(_httpClient);
    }

    updateReportColumnWidth(app: App, reportDefinitionHeader: ReportDefinitionHeader, columnSettings: GridColumnProperties): Observable<Report> {
        const url = `${this.route}${app.id}/report-definition/${reportDefinitionHeader.id}/update-column-width`;
        return this.httpPost(url, columnSettings);
    }

    updateReportDefinition(app: App, project: Project, reportDefinition: ReportDefinition): Observable<any> {
        const url = `${this.route}${app.id}/proj/${project.id}`;
        return this.httpPost(url, reportDefinition);
    }

    removeReportDefinitionFromProject(reportDefinitionId: string, app: App): Observable<any> {
        const url = `${this.route}${app.id}/reportDefinitionId/${reportDefinitionId}`;
        return this.httpDelete(url);
    }

    getReportDefinitionsByProjId(app: App, project: Project): Observable<any> {
        const url = `${this.route}${app.id}/proj/${project.id}`;
        return this.httpGet(url);
    }

    createNewReportDefinitionView(app: App, report: Report, reportView: ReportView): Observable<ReportView> {
        const url = `${this.route}${app.id}/report-definition/${report.reportDefinitionId}/add-view-to-report-definition`;
        return this.httpPost(url, reportView);
    }

    updateReportView(app: App, reportView: ReportView): Observable<ReportView> {
        const url = `${this.route}${app.id}/report-definition/${reportView.reportDefinitionId}/update-view`;
        return this.httpPut(url, reportView);
    }

    deleteReportView(app: App, reportView: ReportView): Observable<ReportView[]> {
        const url = `${this.route}${app.id}/report-definition/${reportView.reportDefinitionId}/delete-view/${reportView.id}`;
        return this.httpDelete(url);
    }

}
