import { Injectable } from '@angular/core';
import { Project } from 'src/app/models/project';
import { Role } from 'src/app/models/role';
import { ObjectProperties } from './logic-services';
import { FieldsLogicService } from './logic-services/fields-logic.service';
import { RuleProperties } from './logic-services/role-rule-options';

@Injectable({
    providedIn: 'root'
})
export class JsonFormsService {

    constructor(
        private _fieldsLogicService: FieldsLogicService,
    ) { }

    getFieldRule(field: any) {
        let rule;
        if (field.show) {
            if (field.enable) {
                rule = {
                    "rule": {
                        "effect": RuleProperties.Enable,
                        "condition": {
                            "scope": "#",
                            "schema": {}
                        }
                    }
                };
            }
            else {
                rule = {
                    "rule": {
                        "effect": RuleProperties.Enable,
                        "condition": {
                            "scope": "#",
                            "schema": { not: {} }
                        }
                    }
                };
            }
        }
        else {
            rule = {
                "rule": {
                    "effect": RuleProperties.Show,
                    "condition": {
                        "scope": "#",
                        "schema": { not: {} }
                    }
                }
            };
        }
        return rule;
    }

    parseFields(project: Project): any[] {
        let fields = [];

        function loopThroughSections(obj, _this): void {
            if (!obj) return;

            let keys = Object.keys(obj);
            keys.forEach(key => {
                if (key === ObjectProperties.FieldType) {
                    fields.push({
                        Name: obj[ObjectProperties.Name],
                        id: obj[ObjectProperties.Id],
                        FieldType: obj[ObjectProperties.FieldType],
                    });
                }

                if (
                    Array.isArray(obj[key]) ||
                    (typeof obj[key] === ObjectProperties.Object &&
                        obj[key] !== null)
                ) {
                    loopThroughSections(obj[key], _this);
                }
            });
        }

        if (project?.JSONForms) {
            loopThroughSections(project.JSONForms.Sections, this);
        }
        fields = fields.filter(field => {
            return !field.FieldType?.options?.readOnly ? true : false;
        });
        fields.sort((a, b) => (a.Name > b.Name) ? 1 : -1);
        return fields;
    }

    parseFieldRuleTable(project: Project, role: Role) {
        let ruleFields = [];
        let prevGroupid;

        function loopThroughSections(obj, role, _this) {
            let found;
            let keys = Object.keys(obj).sort().reverse();
            for (let i = 0; i < keys.length; i++) {
                if (keys[i] === ObjectProperties.SectionTitle) {
                    ruleFields.push({
                        Name: obj[ObjectProperties.SectionTitle],
                        type: ObjectProperties.Section,
                        id: obj[ObjectProperties.Id],
                        isGroupBy: true,
                    });
                    prevGroupid = obj[ObjectProperties.Id];
                }
                else if (keys[i] === ObjectProperties.GroupTitle) {
                    ruleFields.push({
                        Name: obj[ObjectProperties.GroupTitle],
                        type: ObjectProperties.Group,
                        id: obj[ObjectProperties.Id],
                        isGroupBy: true,
                        parentGroup: prevGroupid,
                    });
                    prevGroupid = obj[ObjectProperties.Id];
                }
                else if (keys[i] === ObjectProperties.FieldType) {
                    let show = false,
                        enable = false;

                    if (role && role[RuleProperties.Rules]) {
                        if (role[RuleProperties.Rules][obj[RuleProperties.Id]]) {
                            show =
                                _this.parseFieldRule(
                                    role[RuleProperties.Rules][obj[RuleProperties.Id]][RuleProperties.Rule],
                                    RuleProperties.Show
                                ) == null
                                    ? true
                                    : _this.parseFieldRule(
                                        role[RuleProperties.Rules][obj[RuleProperties.Id]][RuleProperties.Rule],
                                        RuleProperties.Show
                                    );
                            enable =
                                _this.parseFieldRule(
                                    role[RuleProperties.Rules][obj[RuleProperties.Id]][RuleProperties.Rule],
                                    RuleProperties.Enable
                                ) == null
                                    ? false
                                    : _this.parseFieldRule(
                                        role[RuleProperties.Rules][obj[RuleProperties.Id]][RuleProperties.Rule],
                                        RuleProperties.Enable
                                    );
                        }
                    }

                    ruleFields.push({
                        Name: obj[ObjectProperties.Name],
                        id: obj[ObjectProperties.Id],
                        show: show,
                        enable: enable,
                        FieldType: obj[ObjectProperties.FieldType],
                        parentGroup: prevGroupid,
                    });
                }

                if (
                    Array.isArray(obj[Object.keys(obj)[i]]) ||
                    (typeof obj[Object.keys(obj)[i]] === ObjectProperties.Object &&
                        obj[Object.keys(obj)[i]] !== null)
                ) {
                    loopThroughSections(obj[Object.keys(obj)[i]], role, _this);
                }
            }
        }

        if (project && project.JSONForms) {
            loopThroughSections(project.JSONForms.Sections, role, this);
        }

        return ruleFields;
    }

    parseFieldRule(rule: any, type: string): boolean {
        let effect = rule['effect'];

        if (effect === type) {
            let status = rule[RuleProperties.Condition][RuleProperties.Schema];

            if (Object.keys(status).length === 0) {
                return true;
            }
            else if (Object.keys(status[RuleProperties.Not]).length === 0) {
                return false;
            }
            else {
                return true;
            }
        }

        return null;
    }

    setRules(project: Project, role: Role): Project {
        if (!project.JSONForms) {
            return project;
        }

        let defaultRole = {
            "effect": RuleProperties.Show,
            "condition": {
                "scope": "#",
                "schema": { not: {} }
            }
        };

        let fields = this._fieldsLogicService.getAllFieldsAndReadOnlyFields(project);

        for (const field of fields) {
            let rule = role.rules[field.id] ? role.rules[field.id][RuleProperties.Rule] : defaultRole;

            let isFieldHidden = rule.effect === RuleProperties.Show && rule.condition.schema.not !== undefined;

            this.findFieldInSchemaAndApplyRule(
                project.JSONForms.UISchema,
                field.id,
                rule,
                isFieldHidden
            );
        }

        return project;
    }

    findFieldInSchemaAndApplyRule(object, fieldId, rule, isFieldHidden, parentIds = []): any {
        for (let key in object) {
            this.initalizeSectionAndAddToParents(object, parentIds);

            if (object[key] === fieldId) {
                object[RuleProperties.Rule] = rule;
                return parentIds;
            }

            if (!Array.isArray(object[key]) && typeof object[key] !== ObjectProperties.Object && object[key] !== null) {
                continue;
            }

            let parentIdsToUpdate = this.findFieldInSchemaAndApplyRule(object[key], fieldId, rule, isFieldHidden, [...parentIds]);

            if (parentIdsToUpdate === undefined || parentIdsToUpdate.length <= 0) {
                continue;
            }

            if (object.type === ObjectProperties.Group && object.emptySection && (parentIdsToUpdate.includes(object[ObjectProperties.Id]) || parentIdsToUpdate.includes(object[ObjectProperties.Label]))) {
                object.emptySection = isFieldHidden;
            }

            return parentIdsToUpdate;
        }
    }

    initalizeSectionAndAddToParents(object, parentIds): void {
        if (object.type === ObjectProperties.Group && object.emptySection !== false) {
            object[ObjectProperties.Id] ? parentIds.push(object[ObjectProperties.Id]) : parentIds.push(object[ObjectProperties.Label]);
            object.emptySection = true;
        }
    }
}
