import { Component, EventEmitter, Input, Output } from '@angular/core';

import { KHFileInfo } from '../../../../../models';
import { FileLogicService } from '../../../../../services/logic-services';

@Component({
    selector: 'kh-files-template',
    templateUrl: './kh-files-template.component.html',
    styleUrls: ['./kh-files-template.component.scss']
})
export class KHFilesTemplateComponent {

    @Output() deleteFileEventEmitter = new EventEmitter<KHFileInfo>();
    @Output() previewFileEventEmitter = new EventEmitter<KHFileInfo>();
    @Output() downloadFileEventEmitter = new EventEmitter<KHFileInfo>();

    @Input() selectedFiles: KHFileInfo[] = [];

    @Input() isEditable = true;
    @Input() isDownloadable = true;
    @Input() isPreviewable = true;

    @Input() isLoading = false;

    selectedFile: KHFileInfo;

    constructor(
        private _fileLogicService: FileLogicService
    ) { }

    setSelectedFileForMenuActions(selectedFileInfo: KHFileInfo): void {
        this.selectedFile = selectedFileInfo;
    }

    deleteFile(selectedFile: KHFileInfo): void {
        this.deleteFileEventEmitter.emit(selectedFile);
    }

    previewFile(selectedFile: KHFileInfo): void {
        this.previewFileEventEmitter.emit(selectedFile);
    }

    downloadFile(selectedFile: KHFileInfo): void {
        this.downloadFileEventEmitter.emit(selectedFile);
    }

    getFileSize(fileSize: number): string {
        return this._fileLogicService.niceFormatBytes(fileSize);
    }

    getFileIconFromFileInfo(file: KHFileInfo): string {
        return this._fileLogicService.getFileIconFromFileInfo(file);
    }

    isFileTypePreviewable(file: KHFileInfo): boolean {
        return this._fileLogicService.isFileTypePreviewable(file);
    }

    showMenuTogglesForFile(file: KHFileInfo): boolean {
        return this._fileLogicService.showMenuTogglesForFile(file, this.isEditable, this.isDownloadable, this.isPreviewable);
    }
}
