import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { App, Project, Role } from '../models';
import { RolesDataService } from './data-services';
import { ErrorHandlerService } from './tools';

@Injectable({
    providedIn: 'root'
})
export class RolesService {

    private _roles: BehaviorSubject<Role[]> = new BehaviorSubject<Role[]>(null);

    roles$ = this._roles.asObservable();

    roles: Role[] = [];
    role: Role = new Role();

    projectRoles: BehaviorSubject<Role[]> = new BehaviorSubject<Role[]>(null);
    rolesLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    rolesSaving: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    userRole: BehaviorSubject<Role> = new BehaviorSubject<Role>(null);

    constructor(
        private _errorService: ErrorHandlerService,
        private _rolesData: RolesDataService,
    ) {
        let _roles = JSON.parse(sessionStorage.getItem('roles'));

        if (_roles) {
            this.setRoles(_roles);
        }
    }

    init(): void {
        let _roles = JSON.parse(sessionStorage.getItem('roles'));

        if (_roles) {
            this.setRoles(_roles);
        }
    }

    setRoles(roles: Role[]): void {
        sessionStorage.setItem('roles', JSON.stringify(roles));
        this.roles = roles;
        this._roles.next(roles);
    }

    setRole(role: Role): void {
        sessionStorage.setItem('role', JSON.stringify(role));
        this.role = role;
        this.userRole.next(role);
    }

    disconnectRoles(): void {
        sessionStorage.removeItem('roles');
        this.roles = [];
        this._roles.next(null);
        this.projectRoles.next(null);
    }

    disconnectRole(): void {
        sessionStorage.removeItem('role');
        this.userRole.next(null);
    }

    resetRoles(): void {
        this._roles.next(this.roles);
    }

    addUpdateRole(role: Role): void {
        if (this.roles.find((u) => u.id === role.id)) {
            let index = this.roles.findIndex((u) => u.id === role.id);
            this.roles[index] = role;
        }
        else {
            this.roles.push(role);
        }

        this.resetRoles();
    }

    getAllRoles(app: App): void {
        this.rolesLoading.next(true);

        this._rolesData.getAllRoles(app)
            .subscribe({
                next: (response: any) => {
                    this.roles = response;
                    this.setRoles(response);
                },
                error: (error: any) => {
                    this._errorService.showMessage(error, `Error loading roles.`);
                }
            })
            .add(() => {
                this.rolesLoading.next(false);
            });
    }

    getProjectRoles(projectId: string, returnLocal = false): Role[] {
        this.rolesLoading.next(true);
        let roles = this.roles.filter(x => {
            return x.projectId === projectId;
        });

        if (returnLocal) {
            return roles;
        }
        else {
            this._roles.next(roles);
        }
        this.rolesLoading.next(false);
    }

    filterProjectRoles(project: Project): Role[] {
        return this.roles.filter(x => {
            return x.projectId === project.id;
        });
    }

    updateRole(app: App, role: Role, filter: string[] = null): Observable<any> {
        this.rolesSaving.next(true);

        const result$ = new Subject<any>();

        if (!filter) {
            this._rolesData.updateRole(app, role)
                .subscribe({
                    next: (value: any) => {
                        result$.next(value);
                        result$.complete();
                        this.addUpdateRole(value);
                        this.rolesSaving.next(false);
                    },
                    error: (error: any) => {
                        result$.error(error);
                    }
                });
        }
        else {
            this._rolesData.updateRoleWithFilter(app, role, filter)
                .subscribe({
                    next: (value: any) => {
                        result$.next(value);
                        result$.complete();
                        this.addUpdateRole(value);
                        this.rolesSaving.next(false);
                    }
                });
        }

        return result$.asObservable();
    }

    deleteRole(app: App, role: Role): Observable<any> {
        const result$ = new Subject<any>();

        this._rolesData.deleteRole(app, role)
            .subscribe({
                next: (value: any) => {
                    result$.next(value);
                    result$.complete();
                    this.roles = this.roles.filter(x => x.id !== role.id);
                    this.resetRoles();
                },
                error: (error: any) => {
                    result$.error(error);
                }
            });

        return result$.asObservable();
    }
}
