import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'kh-close-dialog-button',
    templateUrl: './close-dialog-button.component.html',
    styleUrls: ['./close-dialog-button.component.scss']
})
export class CloseDialogButtonComponent {
    @Output() click = new EventEmitter<any>();

    constructor(
    ) { }
}
